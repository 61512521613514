import React, { FC, HTMLAttributes } from 'react';
import { HeaderLogo } from './HeaderLogo';
import classNames from 'classnames';
import './Header.scss';
import { HeaderLinks } from './HeaderLinks';
import { HeaderNotifications } from '../../notifications/HeaderNotifications';
import SvgUserRounded from '../icons/UserRounded';
import { useHeaderUserMenuItems } from './hooks/useHeaderUserMenuItems';
import { DropdownMenu } from '../dropdown/DropdownMenu';
import { useUserEmail } from '../../hooks/useUserEmail';

export const Header: FC<HTMLAttributes<HTMLDivElement>> = ({ children, className, ...attrs }) => {
  const menuItems = useHeaderUserMenuItems();

  return (
    <header className={classNames('Header', className)} {...attrs}>
      <HeaderLogo />
      <HeaderLinks />
      <HeaderNotifications />

      <DropdownMenu items={menuItems} header={useUserEmail()}>
        <SvgUserRounded />
      </DropdownMenu>
    </header>
  );
};
