import * as React from 'react';

function SvgSortDesc2(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.724 20.057c.52-.52 1.365-.52 1.885 0L8 24.447l4.39-4.39a1.333 1.333 0 111.886 1.886l-5.333 5.333c-.52.52-1.365.52-1.886 0l-5.333-5.333a1.333 1.333 0 010-1.886z"
        fill="currentColor"
      />
      <path
        d="M8 5c.736 0 1.333.597 1.333 1.333v20a1.333 1.333 0 11-2.666 0v-20C6.667 5.597 7.264 5 8 5zM21.587 23c-.444 0-.773-.102-.99-.306-.204-.216-.306-.528-.306-.936 0-.264.067-.51.198-.738.133-.228.313-.462.54-.702l3.384-3.564c.505-.54.864-1.02 1.08-1.44.229-.42.343-.84.343-1.26 0-.528-.174-.93-.522-1.206-.337-.276-.829-.414-1.477-.414-.348 0-.707.054-1.08.162-.36.096-.738.258-1.134.486-.264.144-.51.198-.738.162a1.116 1.116 0 01-.558-.306 1.228 1.228 0 01-.306-.594 1.128 1.128 0 01.072-.684c.096-.228.276-.42.54-.576a6.12 6.12 0 011.729-.72 7.108 7.108 0 011.854-.252c.947 0 1.745.144 2.393.432.648.276 1.14.684 1.476 1.224.337.54.505 1.2.505 1.98 0 .504-.078.996-.235 1.476-.155.48-.402.966-.738 1.458-.323.48-.756.996-1.296 1.548l-3.15 3.204v-.72h4.878c.384 0 .672.096.864.288.204.192.306.474.306.846s-.102.66-.306.864c-.192.192-.48.288-.864.288h-6.462z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgSortDesc2;
