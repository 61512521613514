import React, { FC, useMemo } from 'react';
import { HeaderLink } from './HeaderLink';
import classNames from 'classnames';
import { RoutePath } from '../../enums/RoutePath';
import { MenuItemProps } from '../../interfaces/MenuItemProps';
import './HeaderLinks.scss';
import { HeaderButton } from './HeaderButton';
import { useOpenVilog } from '../../hooks/useOpenVilog';
import SvgExternal from '../icons/External';
import { useTranslation } from 'react-i18next';

interface Props {
  className?: string;
}

export const HeaderLinks: FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  const openVilog = useOpenVilog();
  const links: MenuItemProps[] = useMemo(
    () => [
      {
        title: t('Dashboard'),
        routePath: RoutePath.Home,
      },
      {
        title: t('Assets'),
        routePath: RoutePath.Assets,
      },
      {
        title: t('Customers'),
        routePath: RoutePath.Customers,
      },
      {
        title: t('Transactions'),
        routePath: RoutePath.Transactions,
      },
      {
        title: t('Users'),
        routePath: RoutePath.Users,
      },
      {
        title: (
          <>
            {t('Map')} <SvgExternal className="mx-2 small" />
          </>
        ),
        onClick: () => openVilog('/'),
      },
    ],
    [t, openVilog],
  );

  return (
    <div className={classNames('HeaderLinks', className)}>
      {links.map(({ title, routePath, onClick }, index) =>
        onClick ? (
          <HeaderButton onClick={onClick} key={index}>
            {title}
          </HeaderButton>
        ) : (
          <HeaderLink routePath={routePath as RoutePath} key={index}>
            {title}
          </HeaderLink>
        ),
      )}
    </div>
  );
};
