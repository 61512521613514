import React, { FC, useCallback, useRef, useState } from 'react';
import { Button, Overlay, Popover } from 'react-bootstrap';
import { AdminTableFilterProps } from '../interfaces/AdminTableFilterProps';
import { AdminTableDateFilterPopup } from './AdminTableDateFilterPopup';
import { OptionalDateRange } from '../interfaces/OptionalDateRange';
import { useAdminTableFilterState } from '../hooks/useAdminTableFilterState';
import { AdminTableDateFilterValue } from './AdminTableDateFilterValue';
import { useTranslation } from 'react-i18next';

export const AdminTableDateFilter: FC<AdminTableFilterProps<OptionalDateRange>> = (props) => {
  const { t } = useTranslation();
  const [range, setRange] = useAdminTableFilterState<OptionalDateRange | null>(null, props);
  const [shown, setShown] = useState(false);
  const show = useCallback(() => setShown(true), [setShown]);
  const hide = useCallback(() => setShown(false), [setShown]);

  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  return (
    <div className="AdminTableDateFilter" ref={containerRef}>
      <Button className="button" variant="outline-primary" size="sm" onClick={show} ref={buttonRef}>
        {range ? <AdminTableDateFilterValue range={range} /> : t('Filter by date')}
      </Button>

      <Overlay show={shown} onHide={hide} container={containerRef} target={buttonRef} rootClose={true}>
        <Popover id="admin-table-date-filter-popover">
          <AdminTableDateFilterPopup range={range} setRange={setRange} hide={hide} />
        </Popover>
      </Overlay>
    </div>
  );
};
