import React, { FC, PropsWithChildren } from 'react';
import './AdminToolbar.scss';
import classNames from 'classnames';

interface Props extends PropsWithChildren {
  className?: string;
}

export const AdminToolbar: FC<Props> = ({ children, className }) => {
  return <div className={classNames('AdminToolbar', className)}>{children}</div>;
};
