import { useFormContext } from 'react-hook-form';
import { AssetDto } from '../dto/AssetDto';
import { FieldError } from 'react-hook-form/dist/types/errors';

export function useAssetsAlertsError(): string | null {
  const { alerts } = useFormContext<AssetDto>().formState.errors;

  if (alerts) {
    const alert = Object.values(alerts)[0];

    if (alert) {
      const field: FieldError = Object.values(alert)[0];

      if (typeof field?.message === 'string') {
        return field.message;
      }
    }
  }

  return null;
}
