import { FC, useContext } from 'react';
import './DashboardTable.scss';
import { useTranslation } from 'react-i18next';
import { DashboardContext } from '../DashboardContext';
import { DashboardTableContentType } from '../enums/DashboardTableContentType';
import { DashboardTableRow } from './DashboardTableRow';

export const DashboardTable: FC = () => {
  const { t } = useTranslation();
  const { tableContentType } = useContext(DashboardContext);

  return (
    <div className="DashboardTable">
      {tableContentType === DashboardTableContentType.Unassigned ? (
        <>
          <h3 className="title">{t('Unassigned assets')}</h3>
          <div className="description">{t('Assets that have left the warehouse but are not assigned to anyone.')}</div>

          <table className="table">
            <tbody>
              {Array.from({ length: 7 }, (_, index) => (
                <DashboardTableRow key={index} />
              ))}
            </tbody>
          </table>
        </>
      ) : (
        <>
          <h3 className="title">{t('Returned assets')}</h3>
          <div className="description">{t('Assets returned to warehouse but not detached from customer.')}</div>

          <div className="empty">{t('There are no such assets.')}</div>
        </>
      )}
    </div>
  );
};
