import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { Dropdown } from 'react-bootstrap';
import { DropdownMenuItem } from './DropdownMenuItem';
import './DropdownMenu.scss';
import classNames from 'classnames';
import { MenuItem } from '../../interfaces/MenuItem';
import { DropdownMenuToggle } from './DropdownMenuToggle';

interface Props extends PropsWithChildren {
  items: MenuItem[];
  header?: ReactNode;
  className?: string;
}

export const DropdownMenu: FC<Props> = ({ children, header, className, items }) => {
  return (
    <Dropdown className={classNames('DropdownMenu', className)}>
      <Dropdown.Toggle as={DropdownMenuToggle} bsPrefix="toggle">
        {children}
      </Dropdown.Toggle>

      <Dropdown.Menu role="list">
        {header && <Dropdown.Header>{header}</Dropdown.Header>}

        {items.map((item, index) =>
          item === null ? <Dropdown.Divider key={index} /> : <DropdownMenuItem {...item} key={index} />,
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};
