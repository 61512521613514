import { ButtonHTMLAttributes, FC } from 'react';
import classNames from 'classnames';
import './DashboardTableCancelButton.scss';
import { useTranslation } from 'react-i18next';

export const DashboardTableCancelButton: FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({
  className,
  children,
  ...attrs
}) => {
  const { t } = useTranslation();

  return (
    <button className={classNames('DashboardTableCancelButton', className)} {...attrs}>
      {t('Cancel ({{seconds}}s)', {
        seconds: 10,
      })}
    </button>
  );
};
