import { useForm, UseFormReturn } from 'react-hook-form';
import { MultipleAssignmentDto } from '../dto/MultipleAssignmentDto';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTableSelectContext } from '../../components/table/TableSelectContext';
import { Asset } from '../dto/Asset';

export function useAssetsMultipleAssignmentForm(): UseFormReturn<MultipleAssignmentDto> {
  const { t } = useTranslation();
  const { selectedItems } = useTableSelectContext<Asset>();
  const resolver = useMemo(
    () =>
      yupResolver(
        yup.object().shape({
          customerId: yup
            .number()
            .label(t('Customer'))
            .required(t('Please select a customer').toString())
            .typeError(t('Please select a customer').toString()),
          assetIds: yup.array().label(t('Assets')).of(yup.number()).required(),
        }),
      ),
    [t],
  );
  const assetIds: MultipleAssignmentDto['assetIds'] = selectedItems.map((selectedItem) => selectedItem.id);
  const defaultValues = { assetIds } as MultipleAssignmentDto;

  return useForm({
    resolver,
    defaultValues,
  });
}
