import { useCallback, useEffect, useMemo, useState } from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import { api } from '../functions/api';

export type ApiData<T> = [T, boolean, (loading?: boolean) => void];

interface ExecutionConfig {
  loading: boolean;
}

export function useApiData<T>(requestConfig: AxiosRequestConfig, initialState: T | (() => T)): ApiData<T>;
export function useApiData<T>(requestConfig: AxiosRequestConfig): ApiData<T | undefined>;
export function useApiData<T>(requestConfig: AxiosRequestConfig, initialState?: T | (() => T)): ApiData<T | undefined> {
  const [value, setValue] = useState<T | undefined>(initialState);
  const [executionConfig, setExecutionConfig] = useState<ExecutionConfig>({ loading: true });
  const [loading, setLoading] = useState(executionConfig.loading);
  const update = useCallback((loading = true) => setExecutionConfig({ loading }), []);

  useEffect(() => {
    const source = axios.CancelToken.source();

    setLoading(executionConfig.loading);

    api<T>({ cancelToken: source.token, ...requestConfig })
      .then((value) => {
        setValue(value);
        setLoading(false);
      })
      .catch((reason) => {
        if (!(reason instanceof axios.Cancel)) {
          setLoading(false);
        }
      });

    return () => source.cancel();
  }, [requestConfig, executionConfig]);

  return useMemo(() => [value, loading, update], [value, loading, update]);
}
