import { AdminTableColumn } from '../interfaces/AdminTableColumn';
import { DispatchWithoutAction, useCallback, useContext } from 'react';
import { TableSortingDirection } from '../enums/TableSortingDirection';
import { AdminTableContext } from '../contexts/AdminTableContext';
import { getLastValueMap } from '../../../functions/getLastValueMap';

export function useTableColumnSortingToggle(column: AdminTableColumn<any>): DispatchWithoutAction {
  const { setColumnDirections } = useContext(AdminTableContext);

  return useCallback(() => {
    setColumnDirections((columnDirections) => {
      const direction = columnDirections.get(column);

      if (direction === undefined) {
        return getLastValueMap(columnDirections).set(column, TableSortingDirection.Asc);
      }

      const next = new Map(columnDirections);

      if (direction === TableSortingDirection.Asc) {
        next.set(column, TableSortingDirection.Desc);
      } else if (direction === TableSortingDirection.Desc) {
        next.delete(column);
      }

      return next;
    });
  }, [column, setColumnDirections]);
}
