import { FC } from 'react';
import { LoginForm } from './login/LoginForm';

export const Login: FC = () => {
  return (
    <div className="Login p-4">
      <LoginForm />
    </div>
  );
};
