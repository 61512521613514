import { FC, HTMLAttributes, ReactNode } from 'react';
import classNames from 'classnames';
import './DashboardChart.scss';

export const DashboardChart: FC<HTMLAttributes<HTMLDivElement> & { title: ReactNode; filter?: ReactNode }> = ({
  className,
  children,
  title,
  filter,
  ...attrs
}) => {
  return (
    <div className={classNames('DashboardChart', className)} {...attrs}>
      <div className="header">
        <div className="title">{title}</div>

        {filter && <div className="filter">{filter}</div>}
      </div>

      <div className="chart">{children}</div>
    </div>
  );
};
