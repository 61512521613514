import { FilterFunction } from '../interfaces/FilterFunction';
import { isInOptionalDateRange } from './isInOptionalDateRange';
import { OptionalDateRange } from '../interfaces/OptionalDateRange';
import { isDateInvalid } from '../../../functions/isDateInvalid';

export function createDateFilterFunction<T>(predicate: (item: T) => any): FilterFunction<T, OptionalDateRange> {
  return (item: T, range: OptionalDateRange) => {
    const value = predicate(item);

    if (!value) {
      return false;
    }

    const date = new Date(value);

    if (isDateInvalid(date)) {
      return false;
    }

    return isInOptionalDateRange(date, range);
  };
}
