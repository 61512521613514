import { Dispatch, useCallback } from 'react';
import { getTemporaryToken } from '../functions/getTemporaryToken';
import { vilogUrl } from '../constants';

const url = vilogUrl.endsWith('/') ? vilogUrl : vilogUrl + '/';

export function useOpenVilog(): Dispatch<string> {
  return useCallback(async (path: string) => {
    const token = await getTemporaryToken();

    window.open(
      `${url}login/assets?path=${window.encodeURIComponent(path)}&token=${window.encodeURIComponent(token)}`,
      '_blank',
    );
  }, []);
}
