import React, { FC, ReactNode } from 'react';
import { AdminTwoLines } from './AdminTwoLines';
import { AdminIsoDate } from './AdminIsoDate';

interface Props {
  date: string | null;
  description?: ReactNode;
}

export const AdminDate: FC<Props> = ({ date, description }) => {
  return <AdminTwoLines first={<AdminIsoDate isoDate={date} />} second={description} />;
};
