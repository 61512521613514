import { OptionalDateRange } from '../interfaces/OptionalDateRange';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';

export function isInOptionalDateRange(date: Date, [from, to]: OptionalDateRange): boolean {
  const start = from && startOfDay(from);
  const end = to && endOfDay(to);

  if (start && date < start) {
    return false;
  }

  if (end && date > end) {
    return false;
  }

  return true;
}
