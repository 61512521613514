import { Dispatch, SetStateAction, useCallback, useRef } from 'react';
import { useOnOutside } from '../../hooks/useOnOutside';

export function useNotificationsOutsideClick(setOpen: Dispatch<SetStateAction<boolean>>) {
  const elementRef = useRef<HTMLDivElement | null>(null);

  useOnOutside(
    elementRef,
    useCallback(() => setOpen(false), [setOpen]),
  );

  return elementRef;
}
