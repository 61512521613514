import { useMemo } from 'react';
import { useSearchParam } from './useSearchParam';

export function useNumberSearchParam(key: string): number | undefined {
  const searchParam = useSearchParam(key);

  return useMemo(() => {
    if (searchParam) {
      const float = parseFloat(searchParam);

      if (!isNaN(float)) {
        return float;
      }
    }
  }, [searchParam]);
}
