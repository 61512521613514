import React, { FC, useMemo, useState } from 'react';
import { AdminTable } from '../components/table/AdminTable';
import { ApiPath } from '../enums/ApiPath';
import { AdminTableActionsItem } from '../components/table/interfaces/AdminTableActionsItem';
import { AdminToolbar } from '../components/toolbar/AdminToolbar';
import { AdminToolbarTitle } from '../components/toolbar/AdminToolbarTitle';
import { Asset } from './dto/Asset';
import { AdminToolbarActions } from '../components/toolbar/AdminToolbarActions';
import { AdminToolbarButton } from '../components/toolbar/AdminToolbarButton';
import { AssetsCreate } from './AssetsCreate';
import { Modal } from 'react-bootstrap';
import { AssetsEdit } from './AssetsEdit';
import { useDuplicateAsset } from './hooks/useDuplicateAsset';
import { api } from '../functions/api';
import { useDeleteItem } from '../hooks/useDeleteItem';
import { useAssetColumns } from './hooks/useAssetColumns';
import { useTranslation } from 'react-i18next';
import { useShowAssetOnMap } from '../hooks/useShowAssetOnMap';
import { TableSelectContext, TableSelectContextProvider } from '../components/table/TableSelectContext';
import { AssetsMultipleAssign } from './AssetsMultipleAssign';
import { AssetsFilter } from './AssetsFilter';
import { useAssets } from './hooks/useAssets';
import { useAssetsPackSearchParam } from './hooks/useAssetsPackSearchParam';

export const Assets: FC = () => {
  const packId = useAssetsPackSearchParam();
  const [items, loading, update] = useAssets({ packId });
  const { t } = useTranslation();
  const [editableAsset, setEditableAsset] = useState<Asset | null>(null);
  const deleteAsset = useDeleteItem<Asset>((item) =>
    api({
      url: ApiPath.Asset.replace(':id', item.id.toString()),
      method: 'delete',
    }),
  );
  const duplicateAsset = useDuplicateAsset();
  const showAssetOnMap = useShowAssetOnMap();
  const actions = useMemo<AdminTableActionsItem<Asset>[]>(
    () => [
      {
        title: t('Show on map'),
        onClick: (asset) => showAssetOnMap(asset),
      },
      {
        title: t('Edit'),
        onClick: setEditableAsset,
      },
      {
        title: t('Duplicate'),
        onClick: (asset) => duplicateAsset(asset).then(() => update()),
      },
      {
        title: t('Delete'),
        onClick: (item) => {
          if (window.confirm(t('Are you sure you want to delete?').toString())) {
            deleteAsset(item).then(() => update());
          }
        },
      },
    ],
    [showAssetOnMap, deleteAsset, duplicateAsset, t, update],
  );
  const columns = useAssetColumns();
  const [adding, setAdding] = useState(false);

  return (
    <TableSelectContextProvider allItems={items}>
      <AdminToolbar>
        <AdminToolbarTitle>{t('Assets')}</AdminToolbarTitle>

        <AssetsFilter />

        <TableSelectContext.Consumer>
          {({ selectedItems }) => (selectedItems.length ? <AssetsMultipleAssign update={update} /> : null)}
        </TableSelectContext.Consumer>

        <AdminToolbarActions>
          <AdminToolbarButton onClick={() => setAdding(true)}>{t('Add asset')}</AdminToolbarButton>
        </AdminToolbarActions>
      </AdminToolbar>

      <AdminTable items={items} loading={loading} columns={columns} actions={actions} />

      <Modal show={adding} onHide={() => setAdding(false)}>
        <AssetsCreate update={update} />
      </Modal>

      <Modal show={!!editableAsset} onHide={() => setEditableAsset(null)}>
        {editableAsset ? <AssetsEdit update={update} asset={editableAsset} setAsset={setEditableAsset} /> : null}
      </Modal>
    </TableSelectContextProvider>
  );
};
