import React, { FC, useMemo } from 'react';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import isValid from 'date-fns/isValid';
import { dateFormat } from '../../constants';
import './AdminIsoDate.scss';

interface Props {
  isoDate?: string | null;
  otherwise?: string;
}

export const AdminIsoDate: FC<Props> = ({ isoDate, otherwise = '-' }) => {
  const value = useMemo(() => {
    if (typeof isoDate === 'string') {
      const date = parseISO(isoDate);

      if (isValid(date)) {
        return format(date, dateFormat);
      }
    }

    return otherwise;
  }, [isoDate, otherwise]);

  return <span className="AdminIsoDate">{value}</span>;
};
