import * as React from 'react';

function SvgUserRounded(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.002 0c-4.571 0-8.308 3.737-8.308 8.308 0 4.572 3.737 8.309 8.308 8.309 4.572 0 8.309-3.737 8.309-8.309C24.31 3.737 20.574 0 16.002 0zM30.26 23.256a7.744 7.744 0 00-.834-1.524c-1.669-2.467-4.244-4.1-7.147-4.498-.363-.037-.762.036-1.052.253a8.74 8.74 0 01-5.225 1.706 8.74 8.74 0 01-5.224-1.706 1.362 1.362 0 00-1.052-.253 10.253 10.253 0 00-7.148 4.498 8.932 8.932 0 00-.834 1.524c-.109.218-.073.472.036.69.29.507.653 1.015.98 1.45.508.69 1.052 1.307 1.669 1.887.508.508 1.088.98 1.669 1.452A16.437 16.437 0 0015.966 32c3.556 0 7.002-1.125 9.868-3.265.581-.436 1.162-.944 1.67-1.451.58-.581 1.16-1.198 1.668-1.887.363-.472.69-.944.98-1.451.181-.218.218-.472.109-.69z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgUserRounded;
