import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  value: boolean;
}

export const AdminBooleanCell: FC<Props> = ({ value }) => {
  const { t } = useTranslation();

  return <>{value ? t('Yes') : t('No')}</>;
};
