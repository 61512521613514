import { NotificationsType } from '../enums/NotificationsType';
import { useContext, useMemo } from 'react';
import { NotificationsContext } from '../NotificationsContext';

export function useTypeNotifications(type: NotificationsType) {
  const { notifications } = useContext(NotificationsContext);

  return useMemo(() => {
    if (type === NotificationsType.Unread) {
      return notifications.filter((notification) => notification.readAt === null);
    } else {
      return notifications.filter((notification) => notification.readAt !== null);
    }
  }, [type, notifications]);
}
