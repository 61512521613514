import React, { FC, useContext } from 'react';
import { AdminTableContext } from './contexts/AdminTableContext';

export const AdminTableFilters: FC = () => {
  const { columns, actions } = useContext(AdminTableContext);

  return (
    <thead className="AdminTableFilters">
    <tr>
      {columns.map(({ filterConfig }, index) => (
        <td key={index}>{filterConfig && <filterConfig.component filter={filterConfig.filter} />}</td>
      ))}

      {actions && actions.length > 0 && <td />}
    </tr>
    </thead>
  );
};
