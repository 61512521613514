import { useForm, UseFormReturn } from 'react-hook-form';
import { AssetDto } from '../dto/AssetDto';
import { useAssetFromResolver } from './useAssetFromResolver';

export function useAssetsCreateForm(): UseFormReturn<AssetDto> {
  const resolver = useAssetFromResolver();

  return useForm({
    resolver,
  });
}
