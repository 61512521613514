import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { Form } from 'react-bootstrap';
import { FormFieldContext, FormFieldContextValue, useFormFieldContext } from './FormFieldContext';
import './FormField.scss';

interface Props extends PropsWithChildren {
  name: string;
  label?: string;
  text?: string;
  render?: (context: FormFieldContextValue) => ReactNode | ReactNode[];
}

export const FormField: FC<Props> = ({ name, label, text, render, children }) => {
  const contextValue = useFormFieldContext(name);

  return (
    <FormFieldContext.Provider value={contextValue}>
      <Form.Group className="FormField" controlId={contextValue.controlId}>
        {label && <Form.Label className="mb-1">{label}</Form.Label>}

        {render?.(contextValue)}

        {children}

        {text && <Form.Text className="text-muted">{text}</Form.Text>}

        {contextValue.error && (
          <Form.Control.Feedback type="invalid">{contextValue.error.message}</Form.Control.Feedback>
        )}
      </Form.Group>
    </FormFieldContext.Provider>
  );
};
