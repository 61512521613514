import React, { FC } from 'react';
import { Form } from 'react-bootstrap';
import { FormField } from '../components/form/FormField';
import { useTranslation } from 'react-i18next';

export const UsersEmailField: FC = () => {
  const { t } = useTranslation();

  return (
    <FormField
      label={t('Email').toString()}
      name="email"
      render={({ invalid, controlAttrs }) => (
        <Form.Control type="email" {...controlAttrs} isInvalid={invalid} autoFocus autoComplete="off" />
      )}
    />
  );
};
