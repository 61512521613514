import * as React from 'react';

function SvgCheck(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M28.396 7.25a2.062 2.062 0 00-2.916 0L12.354 20.375l-5.833-5.833a2.062 2.062 0 10-2.917 2.917l7.292 7.291c.402.403.93.604 1.458.604.528 0 1.056-.2 1.459-.604l14.583-14.584a2.062 2.062 0 000-2.916z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgCheck;
