import { createContext, Dispatch, FC, PropsWithChildren, SetStateAction, useState } from 'react';
import { DashboardTableContentType } from './enums/DashboardTableContentType';

interface DashboardContextValue {
  tableContentType: DashboardTableContentType;
  setTableContentType: Dispatch<SetStateAction<DashboardTableContentType>>;
}

export const DashboardContext = createContext({} as DashboardContextValue);

function useDashboardContextValue(): DashboardContextValue {
  const [tableContentType, setTableContentType] = useState(DashboardTableContentType.Unassigned);

  return { tableContentType, setTableContentType };
}

export const DashboardContextProvider: FC<PropsWithChildren> = ({ children }) => (
  <DashboardContext.Provider value={useDashboardContextValue()}>{children}</DashboardContext.Provider>
);
