import { ApiPath } from '../enums/ApiPath';
import { useApiData } from './useApiData';
import { useMemo } from 'react';
import { AxiosRequestConfig } from 'axios';

export function useApiItems<T>(url: ApiPath | AxiosRequestConfig) {
  return useApiData<T[]>(
    useMemo(
      () =>
        typeof url === 'string'
          ? {
              url,
            }
          : url,
      [url],
    ),
    [],
  );
}
