import {
  createContext,
  Dispatch,
  DispatchWithoutAction,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Notification } from './interfaces/Notification';
import { getNotifications } from './functions/getNotifications';
import { useInterval } from '../hooks/useInterval';

export interface NotificationsContextValue {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  loading: boolean;
  notifications: Notification[];
  setNotifications: Dispatch<SetStateAction<Notification[]>>;
  updateNotifications: DispatchWithoutAction;
  unreadCount: number;
}

export const NotificationsContext = createContext<NotificationsContextValue>({
  open: false,
  setOpen() {},
  loading: false,
  notifications: [],
  setNotifications() {},
  updateNotifications() {},
  unreadCount: 0,
});

export function useNotificationsContext(): NotificationsContextValue {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const updateNotifications = useCallback(() => {
    const afterId = notifications.reduce((result, { id }) => (id > result ? id : result), 0);

    setLoading(true);

    getNotifications(afterId)
      .then((added) =>
        setNotifications((notifications) => (added.length === 0 ? notifications : added.concat(notifications))),
      )
      .finally(() => setLoading(false));
  }, [notifications]);
  const unreadCount = useMemo(
    () => notifications.reduce((result, notification) => (notification.readAt ? result : result + 1), 0),
    [notifications],
  );

  useInterval(updateNotifications, 30000, false);

  useEffect(() => {
    setLoading(true);

    getNotifications()
      .then(setNotifications)
      .finally(() => setLoading(false));
  }, []);

  return useMemo(
    () => ({
      open,
      setOpen,
      loading,
      notifications,
      setNotifications,
      updateNotifications,
      unreadCount,
    }),
    [open, setOpen, loading, notifications, setNotifications, updateNotifications, unreadCount],
  );
}
