import React, { FC, HTMLAttributes } from 'react';
import './Spinner.scss';
import classNames from 'classnames';

interface Props extends HTMLAttributes<HTMLDivElement> {
  borderWidth?: number;
}

export const Spinner: FC<Props> = ({ borderWidth = 3, className, ...attrs }) => {
  return (
    <div className={classNames('Spinner', className)} {...attrs}>
      <div className="arc" style={{ borderWidth: `${borderWidth}px` }} />
    </div>
  );
};
