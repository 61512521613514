import { AssetsFilterDto } from '../interfaces/AssetsFilter';
import { ApiData } from '../../hooks/useApiData';
import { Asset } from '../dto/Asset';
import { ApiPath } from '../../enums/ApiPath';
import { useApiItems } from '../../hooks/useApiItems';
import { useMemo } from 'react';

export function useAssets({ packId }: AssetsFilterDto = {}): ApiData<Asset[]> {
  const params = useMemo(() => {
    const params = new URLSearchParams();

    if (packId) {
      params.append('packId', packId.toString());
    }

    return params;
  }, [packId]);

  return useApiItems<Asset>(
    useMemo(
      () => ({
        url: ApiPath.Assets,
        params,
      }),
      [params],
    ),
  );
}
