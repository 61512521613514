import { FC, useState } from 'react';
import { ChevronDown, ChevronLeft, ChevronRight } from "lucide-react";
import { useTranslation } from 'react-i18next';
import './DashboardTableRow.scss';
import classNames from 'classnames';
import { DashboardTableCustomerSelect } from './DashboardTableCustomerSelect';

export const DashboardTableRow: FC = () => {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);

  return (
    <>
      <tr
        className={classNames('DashboardTableRow -group', {
          '-no-border': opened,
        })}
        onClick={() => setOpened(!opened)}
      >
        <td width={16}>
          {opened ? (
            <ChevronDown size={16} />
          ) : process.env.REACT_APP_LANG === 'he' ? (
            <ChevronLeft size={16} />
          ) : (
            <ChevronRight size={16} />
          )}
        </td>

        <td>21/03/2024 16:48:12</td>

        <td>{t('{{count}} assets', { count: 3 })}</td>

        <td>
          {t('Category')}, {t('Category')}
        </td>

        <td className="action">
          <DashboardTableCustomerSelect caption={t('Assign batch')} />
        </td>
      </tr>

      {opened && (
        <>
          {Array.from({ length: 3 }).map((_, index, collection) => (
            <tr
              className={classNames('DashboardTableRow', {
                '-no-border': index < collection.length - 1,
              })}
              key={index}
            >
              <td></td>
              <td></td>

              <td>{t('Subcategory')}</td>
              <td>{t('Category')}</td>

              <td className="action">
                <DashboardTableCustomerSelect caption={t('Assign')} />
              </td>
            </tr>
          ))}
        </>
      )}
    </>
  );
};
