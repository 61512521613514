import { Asset } from '../dto/Asset';
import { useCallback } from 'react';
import toast from 'react-hot-toast';
import { api } from '../../functions/api';
import { ApiPath } from '../../enums/ApiPath';
import { useTranslation } from 'react-i18next';

export function useDuplicateAsset(): (item: Asset) => Promise<void> {
  const { t } = useTranslation();

  return useCallback((item: Asset) => {
    const id = toast.loading(t('Duplicating'));

    return api({
      url: ApiPath.AssetDuplicate.replace(':id', item.id.toString()),
      method: 'post',
    })
      .then(() => {
        toast.success(t('Duplicated successfully'));
      })
      .catch((reason) => {
        toast.error(t('Error while duplicating'));
        return Promise.resolve(reason);
      })
      .finally(() => toast.dismiss(id));
  }, [t]);
}
