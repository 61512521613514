import React from 'react';
import { useAssetCategories } from './hooks/useAssetCategories';
import { ApiPath } from '../enums/ApiPath';

interface Props {
  listId: string;
  apiPath: ApiPath.AssetCategories | ApiPath.AssetSubcategories;
}

export function AssetsCategoryList({ listId, apiPath }: Props) {
  const categories = useAssetCategories(apiPath);

  return (
    <datalist id={listId}>
      {categories.map((category, index) => (
        <option value={category} key={index} />
      ))}
    </datalist>
  );
}
