import { FC, SelectHTMLAttributes } from 'react';
import classNames from 'classnames';
import './DashboardTableSelect.scss';
import { useTranslation } from "react-i18next";

export const DashboardTableSelect: FC<SelectHTMLAttributes<HTMLSelectElement> & { caption: string }> = ({
  className,
  caption,
  ...attrs
}) => {
  const { t } = useTranslation();

  return (
    <select
      className={classNames('DashboardTableSelect', className)}
      {...attrs}
      onClick={(event) => event.stopPropagation()}
    >
      <option selected>{caption}</option>

      <option>{t('Customer')}</option>
      <option>{t('Customer')}</option>
      <option>{t('Customer')}</option>
      <option>{t('Customer')}</option>
      <option>{t('Customer')}</option>
      <option>{t('Customer')}</option>
    </select>
  );
};
