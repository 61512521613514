import React, { HTMLAttributes, FC } from 'react';
import classNames from 'classnames';
import './AdminToolbarActions.scss';

export const AdminToolbarActions: FC<HTMLAttributes<HTMLDivElement>> = ({ className, children, ...attrs }) => {
  return (
    <div className={classNames('AdminToolbarActions', className)} {...attrs}>
      {children}
    </div>
  );
};
