import React, { FC, useMemo, useState } from 'react';
import { AdminTable } from '../components/table/AdminTable';
import { ApiPath } from '../enums/ApiPath';
import { AdminTableActionsItem } from '../components/table/interfaces/AdminTableActionsItem';
import { AdminToolbar } from '../components/toolbar/AdminToolbar';
import { AdminToolbarTitle } from '../components/toolbar/AdminToolbarTitle';
import { AdminToolbarActions } from '../components/toolbar/AdminToolbarActions';
import { AdminToolbarButton } from '../components/toolbar/AdminToolbarButton';
import { UsersCreate } from './UsersCreate';
import { Modal } from 'react-bootstrap';
import { UsersEdit } from './UsersEdit';
import { api } from '../functions/api';
import { useDeleteItem } from '../hooks/useDeleteItem';
import { useUserColumns } from './hooks/useUserColumns';
import { useApiItems } from '../hooks/useApiItems';
import { User } from './dto/User';
import { UsersPassword } from './UsersPassword';
import { useTranslation } from 'react-i18next';

export const Users: FC = () => {
  const { t } = useTranslation();
  const [editableUser, setEditableUser] = useState<User | null>(null);
  const [passwordUser, setPasswordUser] = useState<User | null>(null);
  const deleteUser = useDeleteItem<User>((item) =>
    api({
      url: ApiPath.User.replace(':id', item.id.toString()),
      method: 'delete',
    }),
  );
  const [items, loading, update] = useApiItems<User>(ApiPath.Users);
  const actions = useMemo<AdminTableActionsItem<User>[]>(
    () => [
      {
        title: t('Edit'),
        onClick: setEditableUser,
      },
      {
        title: t('Change password'),
        onClick: setPasswordUser,
      },
      {
        title: t('Delete'),
        onClick: (item) => {
          if (window.confirm(t('Are you sure you want to delete?').toString())) {
            deleteUser(item).then(() => update());
          }
        },
      },
    ],
    [t, deleteUser, setPasswordUser, setEditableUser, update],
  );
  const columns = useUserColumns();
  const [adding, setAdding] = useState(false);

  return (
    <>
      <AdminToolbar>
        <AdminToolbarTitle>{t('Users')}</AdminToolbarTitle>

        <AdminToolbarActions>
          <AdminToolbarButton onClick={() => setAdding(true)}>{t('Add user')}</AdminToolbarButton>
        </AdminToolbarActions>
      </AdminToolbar>

      <AdminTable items={items} loading={loading} columns={columns} actions={actions} />

      <Modal show={adding} onHide={() => setAdding(false)}>
        <UsersCreate update={update} />
      </Modal>

      <Modal show={!!editableUser} onHide={() => setEditableUser(null)}>
        {editableUser ? <UsersEdit update={update} user={editableUser} /> : null}
      </Modal>

      <Modal show={!!passwordUser} onHide={() => setPasswordUser(null)}>
        {passwordUser ? <UsersPassword user={passwordUser} /> : null}
      </Modal>
    </>
  );
};
