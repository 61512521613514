import React, { FC, useContext } from 'react';
import { AdminTableActions } from './AdminTableActions';
import { AdminTableContext } from './contexts/AdminTableContext';

export const AdminTableBody: FC = () => {
  const { columns, actions, paginatedItems } = useContext(AdminTableContext);

  return (
    <tbody className="AdminTableBody">
      {paginatedItems.map((item, index) => (
        <tr key={index}>
          {columns.map(({ title, predicate, component: Component, style }, columnIndex) => (
            <td style={style} key={columnIndex}>
              {predicate ? predicate(item, index, paginatedItems) : Component ? <Component item={item} /> : null}
            </td>
          ))}

          {actions && actions.length > 0 && (
            <td>
              <AdminTableActions item={item} />
            </td>
          )}
        </tr>
      ))}
    </tbody>
  );
};
