import { AdminTableColumn } from '../interfaces/AdminTableColumn';
import { useContext, useMemo } from 'react';
import { TableSortingDirection } from '../enums/TableSortingDirection';
import { AdminTableContext } from '../contexts/AdminTableContext';

export function useTableColumnSortingDirection(column: AdminTableColumn<any>) {
  const { columnDirections } = useContext(AdminTableContext);

  return useMemo<TableSortingDirection | undefined>(() => columnDirections.get(column), [columnDirections, column]);
}
