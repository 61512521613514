import React, { DispatchWithoutAction, FC, useContext } from 'react';
import { Form } from 'react-bootstrap';
import { FormProvider } from 'react-hook-form';
import { Customer } from './dto/Customer';
import { useCustomersEditForm } from './hooks/useCustomersEditForm';
import ModalContext from 'react-bootstrap/ModalContext';
import { CustomerDto } from './dto/CustomerDto';
import { api } from '../functions/api';
import { ApiPath } from '../enums/ApiPath';
import { useSaveItem } from '../hooks/useSaveItem';
import { useTranslation } from 'react-i18next';
import { FormModalFooter } from '../components/form/FormModalFooter';
import { FormModalHeader } from '../components/form/FormModalHeader';
import { CustomerFormBody } from './CustomerFormBody';

interface Props {
  update: DispatchWithoutAction;
  customer: Customer;
}

export const CustomersEdit: FC<Props> = ({ update, customer }) => {
  const { t } = useTranslation();
  const form = useCustomersEditForm(customer);
  const { onHide } = useContext(ModalContext);

  const save = useSaveItem<CustomerDto>((data) =>
    api({
      url: ApiPath.Customer.replace(':id', customer.id.toString()),
      method: 'patch',
      data,
    }),
  );

  const onSubmit = (data: CustomerDto) => {
    save(data).then(() => {
      update();
      onHide();
    });
  };

  return (
    <FormProvider {...form}>
      <Form onSubmit={form.handleSubmit(onSubmit)}>
        <FormModalHeader>{t('Edit customer')}</FormModalHeader>
        <CustomerFormBody />
        <FormModalFooter />
      </Form>
    </FormProvider>
  );
};
