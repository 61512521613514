import { useCallback, useContext } from 'react';
import { NotificationsContext } from '../NotificationsContext';
import { useNotificationsOutsideClick } from './useNotificationsOutsideClick';
import { useEscapeKeyDown } from '../../hooks/useEscapeKeyDown';

export function useNotificationPopupClose() {
  const { setOpen } = useContext(NotificationsContext);
  const close = useCallback(() => setOpen(false), [setOpen]);
  const elementRef = useNotificationsOutsideClick(close);

  useEscapeKeyDown(close);

  return elementRef;
}
