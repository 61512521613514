import classNames from 'classnames';
import React, { forwardRef, HTMLAttributes } from 'react';
import './DropdownMenuToggle.scss';

export const DropdownMenuToggle = forwardRef<HTMLButtonElement, HTMLAttributes<HTMLButtonElement>>(
  ({ children, onClick, className }, ref) => (
    <button className={classNames('DropdownMenuToggle', className)} ref={ref} onClick={onClick}>
      {children}
    </button>
  ),
);
