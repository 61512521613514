import { FC } from 'react';
import './DashboardReportValue.scss';
import classNames from 'classnames';

export const DashboardReportValue: FC<{
  value: number;
}> = ({ value }) => {
  return (
    <div
      className={classNames('DashboardReportValue', {
        '-warning': value !== 0,
      })}
    >
      {value}
    </div>
  );
};
