import { createContext, Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { AdminTableFilterFunction } from '../interfaces/AdminTableFilterFunction';
import { paginationPageSizes } from '../constants/paginationPageSizes';
import { AdminTableColumn } from '../interfaces/AdminTableColumn';
import { TableSortingDirection } from '../enums/TableSortingDirection';
import { AdminTableActionsItem } from '../interfaces/AdminTableActionsItem';
import { useAdminTableSortedItems } from '../hooks/useAdminTableSortedItems';
import { useAdminTableFilteredItems } from '../hooks/useAdminTableFilteredItems';
import { useAdminTablePaginatedItems } from '../hooks/useAdminTablePaginatedItems';
import { useStorageState } from '../../../hooks/useStorageState';
import { LocalStorageKey } from '../../../enums/LocalStorageKey';

export interface AdminTableContextValue<T> {
  columns: AdminTableColumn<T>[];
  items: T[];
  filteredItems: T[];
  paginatedItems: T[];
  actions?: AdminTableActionsItem<T>[];
  loading: boolean;
  filterFunctions: AdminTableFilterFunction<T>[];
  setFilterFunctions: Dispatch<SetStateAction<AdminTableFilterFunction<T>[]>>;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  pageSize: number;
  setPageSize: Dispatch<SetStateAction<number>>;
  columnDirections: Map<AdminTableColumn<T>, TableSortingDirection>;
  setColumnDirections: Dispatch<SetStateAction<Map<AdminTableColumn<T>, TableSortingDirection>>>;
}

export const AdminTableContext = createContext<AdminTableContextValue<any>>({
  columns: [],
  items: [],
  filteredItems: [],
  paginatedItems: [],
  actions: [],
  loading: false,
  filterFunctions: [],
  setFilterFunctions() {},
  currentPage: 0,
  setCurrentPage() {},
  pageSize: 0,
  setPageSize() {},
  columnDirections: new Map(),
  setColumnDirections() {},
});

interface Props<T> {
  columns: AdminTableColumn<T>[];
  items: T[];
  actions?: AdminTableActionsItem<T>[];
  loading?: boolean;
}

export function useAdminTableContext<T>({
  columns,
  items,
  actions,
  loading = false,
}: Props<T>): AdminTableContextValue<T> {
  const [filterFunctions, setFilterFunctions] = useState<AdminTableFilterFunction<T>[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useStorageState(
    localStorage,
    LocalStorageKey.PaginationPageSize,
    paginationPageSizes[1],
  );
  const [columnDirections, setColumnDirections] = useState(() => new Map());

  const filteredItems = useAdminTableFilteredItems(items, filterFunctions);
  const sortedItems = useAdminTableSortedItems(filteredItems, columnDirections);
  const paginatedItems = useAdminTablePaginatedItems(sortedItems, currentPage, pageSize);

  useEffect(() => setCurrentPage(1), [filteredItems, pageSize]);

  return useMemo(
    () => ({
      columns,
      items,
      filteredItems,
      paginatedItems,
      actions,
      loading,
      filterFunctions,
      setFilterFunctions,
      currentPage,
      setCurrentPage,
      pageSize,
      setPageSize,
      columnDirections,
      setColumnDirections,
    }),
    [
      columns,
      items,
      filteredItems,
      paginatedItems,
      actions,
      loading,
      filterFunctions,
      currentPage,
      pageSize,
      setPageSize,
      columnDirections,
    ],
  );
}
