import { Asset } from '../assets/dto/Asset';
import { useApiData } from './useApiData';
import { AssetAlert } from '../interfaces/AssetAlert';
import { useMemo } from 'react';
import { ApiPath } from '../enums/ApiPath';

export function useAssetAlerts(asset: Asset) {
  return useApiData<AssetAlert[]>(
    useMemo(
      () => ({
        url: ApiPath.AssetAlerts.replace(':id', asset.id.toString()),
      }),
      [asset],
    ),
    [],
  );
}
