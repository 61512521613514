import React, { useMemo } from 'react';
import { AssetImageColumn } from '../AssetImageColumn';
import { createStringFilterFunction } from '../../components/table/functions/createStringFilterFunction';
import { AdminTableInputFilter } from '../../components/table/filters/AdminTableInputFilter';
import { createBooleanFilterFunction } from '../../components/table/functions/createBooleanFilterFunction';
import { AdminTableBooleanFilter } from '../../components/table/filters/AdminTableBooleanFilter';
import { AdminDate } from '../../components/table/AdminDate';
import { AdminTableColumn } from '../../components/table/interfaces/AdminTableColumn';
import { Asset } from '../dto/Asset';
import { createDateFilterFunction } from '../../components/table/functions/createDateFilterFunction';
import { AdminTableDateFilter } from '../../components/table/filters/AdminTableDateFilter';
import { useTranslation } from 'react-i18next';
import { AssetsTakenColumn } from '../AssetsTakenColumn';
import { TableSelectAll } from '../../components/table/TableSelectAll';
import { TableSelectItem } from '../../components/table/TableSelectItem';

export function useAssetColumns(): AdminTableColumn<Asset>[] {
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        title: <TableSelectAll />,
        component: TableSelectItem,
        style: {
          width: 40,
          padding: 0,
        },
      },
      {
        title: t('Title'),
        predicate: (item) => item.title,
        sortingPredicate: (item) => item.title,
        filterConfig: {
          filter: createStringFilterFunction((item) => item.title),
          component: AdminTableInputFilter,
        },
      },
      {
        title: t('GUID'),
        predicate: (item) => item.guid,
        sortingPredicate: (item) => item.guid,
        filterConfig: {
          filter: createStringFilterFunction((item) => item.guid),
          component: AdminTableInputFilter,
        },
      },
      {
        title: t('Category'),
        predicate: (item) => item.category,
        sortingPredicate: (item) => item.category,
        filterConfig: {
          filter: createStringFilterFunction((item) => item.category),
          component: AdminTableInputFilter,
        },
      },
      {
        title: t('Subcategory'),
        predicate: (item) => item.subcategory,
        sortingPredicate: (item) => item.subcategory,
        filterConfig: {
          filter: createStringFilterFunction((item) => item.subcategory),
          component: AdminTableInputFilter,
        },
      },
      {
        title: t('Image'),
        component: AssetImageColumn,
        sortingPredicate: (item) => !!item.imageUrl,
        filterConfig: {
          filter: createBooleanFilterFunction((item) => !!item.imageUrl),
          component: AdminTableBooleanFilter,
        },
        style: {
          width: 76,
        },
      },
      {
        title: t('Taken'),
        predicate: (item) => <AssetsTakenColumn item={item} />,
        sortingPredicate: (item) => item.takenBy,
        filterConfig: {
          filter: createBooleanFilterFunction((item) => !!item.takenBy),
          component: AdminTableBooleanFilter,
        },
      },
      {
        title: t('Created at'),
        predicate: (item) => <AdminDate date={item.createdAt} />,
        sortingPredicate: (item) => item.createdAt,
        filterConfig: {
          filter: createDateFilterFunction((item) => item.createdAt),
          component: AdminTableDateFilter,
        },
        style: {
          width: 160,
        },
      },
    ],
    [t],
  );
}
