import React, { FC, useCallback, useContext } from 'react';
import { NotificationsMenuItem } from './NotificationsMenuItem';
import './NotificationsMenu.scss';
import { NotificationsType } from './enums/NotificationsType';
import { NotificationsPopupContext } from './NotificationsPopupContext';
import { NotificationsContext } from './NotificationsContext';
import { useTranslation } from 'react-i18next';

export const NotificationsMenu: FC = () => {
  const { t } = useTranslation();
  const { unreadCount } = useContext(NotificationsContext);
  const { type, setType } = useContext(NotificationsPopupContext);
  const onArchiveClick = useCallback(() => setType(NotificationsType.Archive), [setType]);
  const onUnreadClick = useCallback(() => setType(NotificationsType.Unread), [setType]);

  return (
    <nav className="NotificationsMenu">
      <NotificationsMenuItem active={type === NotificationsType.Unread} onClick={onUnreadClick} count={unreadCount}>
        {t('Unread')}
      </NotificationsMenuItem>

      <NotificationsMenuItem className="archive" active={type === NotificationsType.Archive} onClick={onArchiveClick}>
        {t('Archive')}
      </NotificationsMenuItem>
    </nav>
  );
};
