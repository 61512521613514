import React, { FC } from 'react';
import { Button, Form } from 'react-bootstrap';
import { FieldArrayWithId, useFieldArray, useFormContext } from 'react-hook-form';
import { AssetDto } from './dto/AssetDto';
import { useTranslation } from 'react-i18next';
import { useDeleteItem } from '../hooks/useDeleteItem';
import { api } from '../functions/api';
import { ApiPath } from '../enums/ApiPath';
import { formatDateForInput } from '../functions/formatDateForInput';
import { useAssetsAlertsError } from './hooks/useAssetsAlertsError';

export const AssetsAlerts: FC = () => {
  const { t } = useTranslation();
  const form = useFormContext<AssetDto>();
  const { fields, append, remove } = useFieldArray<AssetDto, 'alerts', 'arrayId'>({
    control: form.control,
    name: 'alerts',
    keyName: 'arrayId',
  });
  const addAlert = () => {
    append({
      message: '',
      period: 30,
      startedAt: formatDateForInput(new Date()),
    });
  };
  const deleteAlert = useDeleteItem<number>((id) =>
    api({
      url: ApiPath.AssetAlert.replace(':id', id.toString()),
      method: 'delete',
    }),
  );

  const onDeleteClick = (alert: FieldArrayWithId<AssetDto, 'alerts', 'arrayId'>, index: number) => {
    if (alert.id) {
      if (window.confirm(t('Are you sure you want to delete?').toString())) {
        deleteAlert(alert.id).then(() => remove(index));
      }
    } else {
      remove(index);
    }
  };
  const error = useAssetsAlertsError();

  return (
    <Form.Group>
      <div className="form-label mb-1">
        <span className="mx-2">{t('Alerts')}</span>

        <Button size="sm" variant="outline-primary" onClick={addAlert}>
          {t('Add')}
        </Button>
      </div>

      {fields.length > 0 && (
        <>
          <table className="table table-sm mb-0">
            <thead>
              <tr>
                <th>{t('Message')}</th>
                <th>{t('Period (days)')}</th>
                <th>{t('Start date')}</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {fields.map((alert, index) => (
                <tr key={index}>
                  <td>
                    <input type="hidden" value={alert.id} />
                    <Form.Control size="sm" {...form.register(`alerts.${index}.message`)} />
                  </td>

                  <td style={{ width: 100 }}>
                    <Form.Control size="sm" type="number" min={1} {...form.register(`alerts.${index}.period`)} />
                  </td>

                  <td style={{ width: 120 }}>
                    <Form.Control size="sm" type="date" {...form.register(`alerts.${index}.startedAt`)} />
                  </td>

                  <td style={{ width: 1 }} valign="middle">
                    <Button size="sm" variant="outline-danger" onClick={() => onDeleteClick(alert, index)}>
                      &times;
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {error && (
            <Form.Control.Feedback className="d-block" type="invalid">
              {error}
            </Form.Control.Feedback>
          )}
        </>
      )}
    </Form.Group>
  );
};
