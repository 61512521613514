import React, { FC } from 'react';
import { NotificationsContext, useNotificationsContext } from './NotificationsContext';
import { HeaderNotificationsToggle } from './HeaderNotificationsToggle';
import { NotificationsPopup } from './NotificationsPopup';

export const HeaderNotifications: FC = () => {
  const contextValue = useNotificationsContext();
  const { open } = contextValue;

  return (
    <NotificationsContext.Provider value={contextValue}>
      <HeaderNotificationsToggle />

      {open && <NotificationsPopup />}
    </NotificationsContext.Provider>
  );
};
