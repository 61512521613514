import { DispatchWithoutAction, FC, ReactNode } from 'react';
import './DashboardReport.scss';
import classNames from 'classnames';

export const DashboardReport: FC<{
  title: ReactNode;
  value: ReactNode;
  onClick?: DispatchWithoutAction;
  active?: boolean;
}> = ({ title, value, onClick, active }) => {
  const content = (
    <>
      <div className="title">{title}</div>
      <div className="value">{value}</div>
    </>
  );

  return onClick ? (
    <button
      className={classNames('DashboardReport', {
        '-button': onClick,
        '-active': active,
      })}
      onClick={onClick}
    >
      {content}
    </button>
  ) : (
    <div className="DashboardReport">{content}</div>
  );
};
