import { FC } from 'react';
import { DashboardChart } from './DashboardChart';
import { useTranslation } from 'react-i18next';
import { Pie } from 'react-chartjs-2';
import './DashboardLocationsChart.scss';
import { DashboardChartSelect } from './DashboardChartSelect';
import { colors } from '../../colors';

const labels = ['צוות א', 'צוות ב', 'צוות ג', 'צוות ד', 'צוות ה', 'צוות ו'];

export const DashboardLocationsChart: FC = () => {
  const { t } = useTranslation();

  return (
    <DashboardChart
      className="DashboardLocationsChart"
      title={t('Assets locations').toString()}
      filter={
        <DashboardChartSelect className="select">
          <option value="">
            {t('Category')}, {t('Category')}
          </option>
        </DashboardChartSelect>
      }
    >
      <Pie
        className="mx-auto"
        data={{
          labels,
          datasets: [
            {
              label: t('Assets').toString(),
              data: labels.map(() => Math.round(Math.random() * 100)).sort(),
              backgroundColor: colors,
            },
          ],
        }}
      />
    </DashboardChart>
  );
};
