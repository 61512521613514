import { createContext, Dispatch, SetStateAction, useMemo, useState } from 'react';
import { NotificationsType } from './enums/NotificationsType';

export interface NotificationsPopupContextValue {
  type: NotificationsType;
  setType: Dispatch<SetStateAction<NotificationsType>>;
}

export const NotificationsPopupContext = createContext<NotificationsPopupContextValue>({
  type: NotificationsType.Unread,
  setType() {},
});

export function useNotificationsPopupContext(): NotificationsPopupContextValue {
  const [type, setType] = useState<NotificationsType>(NotificationsType.Unread);

  return useMemo(() => ({ type, setType }), [type, setType]);
}
