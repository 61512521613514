import { LoginFormDto } from '../login/login/LoginFormDto';
import { api } from './api';
import { ApiPath } from '../enums/ApiPath';

export function login(data: LoginFormDto): Promise<{ token: string }> {
  return api<{ access_token: string }>({
    url: ApiPath.AuthLogin,
    method: 'post',
    data,
  }).then(({ access_token: token }) => ({
    token,
  }));
}
