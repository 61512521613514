import React, { FC, useContext } from 'react';
import classNames from 'classnames';
import './HeaderNotificationsToggle.scss';
import { NotificationsContext } from './NotificationsContext';
import { useToggle } from '../hooks/useToggle';
import SvgBellFilled from '../components/icons/BellFilled';
import { DropdownMenuToggle } from '../components/dropdown/DropdownMenuToggle';

export const HeaderNotificationsToggle: FC = () => {
  const { setOpen, unreadCount } = useContext(NotificationsContext);
  const toggle = useToggle(setOpen);

  return (
    <DropdownMenuToggle onClick={toggle}>
      <div
        className={classNames('HeaderNotificationsToggle', {
          '-unread': unreadCount > 0,
        })}
      >
        <SvgBellFilled className="icon" />

        {unreadCount > 0 && <div className="count">{unreadCount}</div>}
      </div>
    </DropdownMenuToggle>
  );
};
