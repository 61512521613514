import { DispatchWithoutAction, useCallback } from 'react';
import { SessionStorageKey } from '../../../enums/SessionStorageKey';
import { RoutePath } from '../../../enums/RoutePath';
import { useNavigate } from 'react-router-dom';

export function useLogout(): DispatchWithoutAction {
  const navigate = useNavigate();

  return useCallback(() => {
    sessionStorage.removeItem(SessionStorageKey.Token);
    sessionStorage.removeItem(SessionStorageKey.Username);

    navigate(RoutePath.Login);
  }, [navigate]);
}
