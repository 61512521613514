import React, { FC } from 'react';
import { Form } from 'react-bootstrap';
import { FormField } from '../components/form/FormField';
import { useTranslation } from 'react-i18next';

interface Props {
  autoFocus?: boolean;
}

export const UsersPasswordField: FC<Props> = ({ autoFocus }) => {
  const { t } = useTranslation();

  return (
    <FormField
      label={t('Password').toString()}
      name="password"
      render={({ invalid, controlAttrs }) => (
        <Form.Control type="password" {...controlAttrs} isInvalid={invalid} autoFocus={autoFocus} autoComplete="off" />
      )}
    />
  );
};
