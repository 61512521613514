import React, { FC } from 'react';
import { AdminTableColumn } from './interfaces/AdminTableColumn';
import { AdminTableHeadColumnButton } from './AdminTableHeadColumnButton';

interface Props<T> {
  column: AdminTableColumn<T>;
}

export const AdminTableHeadColumn: FC<Props<any>> = ({ column }) => {
  return (
    <th className="AdminTableHeadColumn" style={column.style}>
      {column.sortingPredicate ? <AdminTableHeadColumnButton column={column} /> : column.title}
    </th>
  );
};
