import * as React from 'react';
import type { SVGProps } from 'react';
const SvgBellFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      d="M16 31.645a2.844 2.844 0 0 0 2.844-2.845h-5.689A2.844 2.844 0 0 0 16 31.645ZM28.692 25.078a1.422 1.422 0 0 0-.309-1.55L25.956 21.1v-7.944a9.956 9.956 0 0 0-8.534-9.842V1.778a1.422 1.422 0 1 0-2.844 0v1.536a9.955 9.955 0 0 0-8.534 9.842V21.1l-2.427 2.428a1.422 1.422 0 0 0 1.005 2.428h22.756a1.423 1.423 0 0 0 1.314-.878Z"
    />
  </svg>
);
export default SvgBellFilled;
