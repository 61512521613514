import React, { FC } from 'react';
import { Notification } from './interfaces/Notification';
import './NotificationsListContent.scss';
import { NotificationsListActions } from './NotificationsListActions';
import { useNotificationActions } from './hooks/useNotificationActions';
import { AdminIsoDate } from '../components/table/AdminIsoDate';

interface Props {
  notification: Notification;
}

export const NotificationsListContent: FC<Props> = ({ notification }) => {
  const actions = useNotificationActions(notification);

  return (
    <div className="NotificationsListContent">
      <div className="title">{notification.title}</div>

      {notification.description && <div className="description">{notification.description}</div>}

      <div className="distance">
        <AdminIsoDate isoDate={notification.createdAt} />
      </div>

      {actions.length > 0 && <NotificationsListActions actions={actions} />}
    </div>
  );
};
