import { useRef } from 'react';

let uniqueId = 0;

export function getUniqueId(): number {
  return uniqueId++;
}

export function useUniqueId(): number {
  const ref = useRef<number>();

  if (ref.current === undefined) {
    ref.current = getUniqueId();
  }

  return ref.current;
}
