import React, { useMemo, useRef } from 'react';
import { useTableSelectContext } from './TableSelectContext';
import { TableSelectCheckbox } from './TableSelectCheckbox';

interface Props<T> {
  item: T;
}

export function TableSelectItem<T>({ item }: Props<T>) {
  const { setSelectedItems, allItems, selectedItems } = useTableSelectContext();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const checked = useMemo<boolean>(
    () => allItems.length === selectedItems.length || selectedItems.includes(item),
    [allItems, selectedItems, item],
  );

  return (
    <TableSelectCheckbox
      onChange={(event) => {
        const { checked } = event.target;

        setSelectedItems((selectedItems) =>
          checked ? selectedItems.concat(item) : selectedItems.filter((selectedItem) => selectedItem !== item),
        );
      }}
      checked={checked}
      ref={inputRef}
    />
  );
}
