import React, { FC, useContext } from 'react';
import { AdminTableHeadColumn } from './AdminTableHeadColumn';
import { AdminTableContext } from './contexts/AdminTableContext';

export const AdminTableHead: FC = () => {
  const { columns, actions } = useContext(AdminTableContext);

  return (
    <thead className="AdminTableHead">
      <tr>
        {columns.map((column, index) => (
          <AdminTableHeadColumn column={column} key={index} />
        ))}

        {actions && actions.length > 0 && <th className="actions" />}
      </tr>
    </thead>
  );
};
