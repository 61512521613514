import { useMemo } from 'react';
import { MenuItem } from '../../../interfaces/MenuItem';
import SvgLogout from '../../icons/Logout';
import { useLogout } from './useLogout';
import { useTranslation } from 'react-i18next';

export function useHeaderUserMenuItems(): MenuItem[] {
  const { t } = useTranslation();
  const logout = useLogout();

  return useMemo<MenuItem[]>(
    () => [
      {
        title: t('Logout'),
        icon: <SvgLogout />,
        onClick: logout,
      },
    ],
    [t, logout],
  );
}
