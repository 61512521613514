import { FC } from 'react';
import './Dashboard.scss';
import { DashboardLocationsChart } from './charts/DashboardLocationsChart';
import { DashboardIssuedChart } from './charts/DashboardIssuedChart';
import { DashboardContextProvider } from './DashboardContext';
import { DashboardReports } from './reports/DashboardReports';
import { DashboardTable } from './table/DashboardTable';
import { DashboardButtons } from './buttons/DashboardButtons';

export const Dashboard: FC = () => {
  return (
    <DashboardContextProvider>
      <div className="Dashboard">
        <DashboardLocationsChart />
        <DashboardIssuedChart />

        <DashboardReports />

        <DashboardTable />

        <DashboardButtons />
      </div>
    </DashboardContextProvider>
  );
};
