import { FC, useContext } from 'react';
import { DashboardReport } from './DashboardReport';
import { useTranslation } from 'react-i18next';
import './DashboardReports.scss';
import { DashboardContext } from '../DashboardContext';
import { DashboardTableContentType } from '../enums/DashboardTableContentType';
import { DashboardReportValue } from './DashboardReportValue';

export const DashboardReports: FC = () => {
  const { t } = useTranslation();
  const { setTableContentType, tableContentType } = useContext(DashboardContext);

  return (
    <div className="DashboardReports">
      <DashboardReport title={t('Total assets')} value={682} />
      <DashboardReport title={t('In stock')} value={520} />
      <DashboardReport title={t('Issued')} value={162} />

      <DashboardReport
        title={t('Unassigned')}
        value={<DashboardReportValue value={24} />}
        onClick={() => setTableContentType(DashboardTableContentType.Unassigned)}
        active={tableContentType === DashboardTableContentType.Unassigned}
      />

      <DashboardReport
        title={t('Returned')}
        value={<DashboardReportValue value={0} />}
        onClick={() => setTableContentType(DashboardTableContentType.Returned)}
        active={tableContentType === DashboardTableContentType.Returned}
      />
    </div>
  );
};
