import React, { FC, useCallback, useContext, useMemo } from 'react';
import { Pagination as BootstrapPagination } from 'react-bootstrap';
import range from 'lodash/range';
import './TablePagination.scss';
import classNames from 'classnames';
import { AdminTableContext } from './contexts/AdminTableContext';
import { FormSelect } from '../form/FormSelect';
import { paginationPageSizes } from './constants/paginationPageSizes';
import { useTranslation } from 'react-i18next';

interface Props {
  small?: boolean;
}

export const TablePagination: FC<Props> = ({ small = false }) => {
  const { t } = useTranslation();
  const {
    filteredItems: { length: totalItems },
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
  } = useContext(AdminTableContext);
  const lastPage = useMemo(() => Math.ceil(totalItems / pageSize), [totalItems, pageSize]);
  const setFirstPage = useCallback(() => setCurrentPage(1), [setCurrentPage]);
  const setPreviousPage = useCallback(() => setCurrentPage((currentPage) => currentPage - 1), [setCurrentPage]);
  const setNextPage = useCallback(() => setCurrentPage((currentPage) => currentPage + 1), [setCurrentPage]);
  const setLastPage = useCallback(() => setCurrentPage(lastPage), [setCurrentPage, lastPage]);
  const items = useMemo(() => {
    if (currentPage < 4) {
      return range(1, Math.min(lastPage, 5) + 1);
    } else if (lastPage - currentPage < 3) {
      return range(Math.max(1, lastPage - 4), lastPage + 1);
    } else {
      return range(currentPage - 2, Math.min(lastPage, currentPage + 2) + 1);
    }
  }, [currentPage, lastPage]);
  const { min, max } = useMemo(() => {
    const min = currentPage * pageSize - pageSize + 1;
    const max = Math.min(currentPage * pageSize, totalItems);

    return { min, max };
  }, [currentPage, pageSize, totalItems]);
  const options = useMemo(
    () =>
      paginationPageSizes.map((value) => ({
        value,
        label: value + ' ' + t('per page'),
      })),
    [t],
  );

  return totalItems ? (
    <div className="TablePagination">
      <div className={classNames('pagination list', { 'pagination-sm': small })}>
        <BootstrapPagination.First disabled={currentPage === 1} onClick={setFirstPage} />
        <BootstrapPagination.Prev disabled={currentPage === 1} onClick={setPreviousPage} />
        {items.map((item) => (
          <BootstrapPagination.Item onClick={() => setCurrentPage(item)} active={item === currentPage} key={item}>
            {item}
          </BootstrapPagination.Item>
        ))}
        <BootstrapPagination.Next disabled={currentPage === lastPage} onClick={setNextPage} />
        <BootstrapPagination.Last disabled={currentPage === lastPage} onClick={setLastPage} />
      </div>

      <FormSelect
        className={classNames('size form-control', { 'form-control-sm': small })}
        options={options}
        value={pageSize}
        onValueChange={setPageSize}
      />

      <div className="total">
        {min} &ndash; {max} {t('of')} {totalItems}
      </div>
    </div>
  ) : null;
};
