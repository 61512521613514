import { FC } from 'react';
import { Customer } from './dto/Customer';
import { AdminIsoDate } from '../components/table/AdminIsoDate';

interface Props {
  customer: Customer;
}

export const CustomersAssets: FC<Props> = ({ customer }) => {
  if (!customer.assets.length) {
    return <>-</>;
  }

  return (
    <ol className="CustomersAssets m-0">
      {customer.assets.map((asset, index) => (
        <li key={index}>
          <div>[{asset.guid}] {asset.title}</div>
          <small className="text-muted">
            <AdminIsoDate isoDate={asset.takenAt} />
          </small>
        </li>
      ))}
    </ol>
  );
};
