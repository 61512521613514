import React, { DispatchWithoutAction, FC } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FormProvider } from 'react-hook-form';
import { FormModalHeader } from '../components/form/FormModalHeader';
import { FormModalFooter } from '../components/form/FormModalFooter';
import { useAssetsMultipleAssignmentForm } from './hooks/useAssetsMultipleAssignmentForm';
import { MultipleAssignmentDto } from './dto/MultipleAssignmentDto';
import { FormField } from '../components/form/FormField';
import { CustomerFormField } from '../customers/CustomerFormField';
import { api } from '../functions/api';
import { ApiPath } from '../enums/ApiPath';
import { AxiosError } from 'axios';
import toast from 'react-hot-toast';

interface Props {
  afterSubmit: DispatchWithoutAction;
}

export const AssetsMultipleAssignFrom: FC<Props> = ({ afterSubmit }) => {
  const { t } = useTranslation();
  const form = useAssetsMultipleAssignmentForm();

  const onSubmit = (data: MultipleAssignmentDto) => {
    const id = toast.loading(t('Saving'));

    return api({
      url: ApiPath.AssetsAssign,
      method: 'post',
      data,
    })
      .then(() => {
        toast.success(t('Saved successfully'));

        afterSubmit();
      })
      .catch((reason: AxiosError) => {
        if (reason.response?.status === 409) {
          toast.error(t('Some assets are already assigned'));
        } else {
          toast.error(t('Error while saving'));
        }

        return Promise.reject(reason);
      })
      .finally(() => toast.dismiss(id));
  };

  return (
    <>
      <FormProvider {...form}>
        <Form onSubmit={form.handleSubmit(onSubmit)}>
          <FormModalHeader>{t('Multiple assignment')}</FormModalHeader>

          <Modal.Body>
            <FormField label={t('Customer').toString()} name="customerId">
              <CustomerFormField />
            </FormField>
          </Modal.Body>

          <FormModalFooter />
        </Form>
      </FormProvider>
    </>
  );
};
