import React, { FC } from 'react';
import { AssetsFilterPack } from './AssetsFilterPack';

export const AssetsFilter: FC = () => {
  return (
    <>
      <AssetsFilterPack />
    </>
  );
};
