import * as React from 'react';

function SvgSortDesc1(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.724 20.057c.52-.52 1.365-.52 1.885 0L8 24.447l4.39-4.39a1.333 1.333 0 111.886 1.886l-5.333 5.333c-.52.52-1.365.52-1.886 0l-5.333-5.333a1.333 1.333 0 010-1.886z"
        fill="currentColor"
      />
      <path
        d="M8 5c.736 0 1.333.597 1.333 1.333v20a1.333 1.333 0 11-2.666 0v-20C6.667 5.597 7.264 5 8 5zM21.93 23c-.384 0-.678-.102-.883-.306-.204-.204-.306-.486-.306-.846 0-.372.102-.654.306-.846.204-.192.498-.288.883-.288h1.817v-8.118h1.314l-2.628 1.602c-.276.168-.534.234-.773.198a1.055 1.055 0 01-.595-.306 1.29 1.29 0 01-.341-.594 1.176 1.176 0 01.072-.702c.096-.24.276-.444.54-.612l2.502-1.512c.252-.156.503-.282.755-.378.265-.096.523-.144.774-.144.36 0 .642.096.846.288.216.18.325.462.325.846v9.432h1.637c.372 0 .66.096.864.288.204.192.306.474.306.846s-.101.66-.305.864c-.204.192-.492.288-.864.288H21.93z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgSortDesc1;
