import React, { FC } from 'react';
import './LoginForm.scss';
import { useLoginSubmit } from './hooks/useLoginSubmit';
import { useLoginForm } from './hooks/useLoginForm';
import { FormProvider } from 'react-hook-form';
import { LoginLayout } from './LoginLayout';
import { FormButton } from '../../components/form/FormButton';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { FormField } from '../../components/form/FormField';

export const LoginForm: FC = () => {
  const { t } = useTranslation();
  const form = useLoginForm();
  const onSubmit = useLoginSubmit(form);

  return (
    <FormProvider {...form}>
      <LoginLayout title={t('Login')}>
        <Form className="LoginForm" name="login" onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            label={t('Email').toString()}
            name="email"
            render={({ invalid, controlAttrs }) => (
              <Form.Control
                type="email"
                autoCorrect="off"
                autoCapitalize="none"
                {...controlAttrs}
                isInvalid={invalid}
                autoFocus
              />
            )}
          />

          <FormField
            label={t('Password').toString()}
            name="password"
            render={({ invalid, controlAttrs }) => (
              <Form.Control
                type="password"
                autoComplete="current-password"
                autoCorrect="off"
                autoCapitalize="none"
                {...controlAttrs}
                isInvalid={invalid}
                autoFocus
              />
            )}
          />

          <div className="actions">
            <FormButton className="btn-primary" type="submit">
              {t('Login')}
            </FormButton>
          </div>
        </Form>
      </LoginLayout>
    </FormProvider>
  );
};
