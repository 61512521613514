import { FC, SelectHTMLAttributes } from 'react';
import classNames from 'classnames';
import './DashboardChartSelect.scss';

export const DashboardChartSelect: FC<SelectHTMLAttributes<HTMLSelectElement>> = ({
  className,
  children,
  ...attrs
}) => {
  return (
    <select className={classNames('DashboardChartSelect', className)} {...attrs}>
      {children}
    </select>
  );
};
