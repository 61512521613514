import React, { useEffect } from 'react';
import './App.scss';
import { Navigate, Route, Routes } from 'react-router-dom';
import { RoutePath } from './enums/RoutePath';
import { Assets } from './assets/Assets';
import { Users } from './users/Users';
import { Customers } from './customers/Customers';
import { Transactions } from './transactions/Transactions';
import { Login } from './login/Login';
import { AppRoute } from './components/AppRoute';
import { Toaster } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { setLocale } from 'yup';
import { Dashboard } from './dashboard/Dashboard';

function App() {
  const { t } = useTranslation();

  useEffect(() => {
    setLocale({
      mixed: {
        required: t('${path} is a required field').toString(),
      },
      string: {
        email: t('${path} must be a valid email').toString(),
      },
      number: {
        min: t('${path} must be greater than or equal to ${min}').toString(),
      },
    });
  }, [t]);

  useEffect(() => {
    document.title = t('Vilog Asset Manager');
  }, [t]);

  return (
    <>
      <Routes>
        <Route
          path={RoutePath.Home}
          element={
            <AppRoute>
              <Dashboard />
            </AppRoute>
          }
        />
        <Route
          path={RoutePath.Assets}
          element={
            <AppRoute>
              <Assets />
            </AppRoute>
          }
        />
        <Route
          path={RoutePath.Customers}
          element={
            <AppRoute>
              <Customers />
            </AppRoute>
          }
        />
        <Route
          path={RoutePath.Transactions}
          element={
            <AppRoute>
              <Transactions />
            </AppRoute>
          }
        />
        <Route
          path={RoutePath.Users}
          element={
            <AppRoute>
              <Users />
            </AppRoute>
          }
        />

        <Route path={RoutePath.Login} element={<Login />} />

        <Route path="*" element={<Navigate to={RoutePath.Home} replace />} />
      </Routes>

      <Toaster />
    </>
  );
}

export default App;
