import React, { Dispatch, DispatchWithoutAction, FC, useContext, useEffect } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { FormProvider } from 'react-hook-form';
import { Asset } from './dto/Asset';
import { useAssetsEditForm } from './hooks/useAssetsEditForm';
import ModalContext from 'react-bootstrap/ModalContext';
import { AssetDto } from './dto/AssetDto';
import { api } from '../functions/api';
import { ApiPath } from '../enums/ApiPath';
import { useSaveItem } from '../hooks/useSaveItem';
import { createAssetFormData } from './functions/createAssetFormData';
import { useTranslation } from 'react-i18next';
import { FormModalFooter } from '../components/form/FormModalFooter';
import { FormModalHeader } from '../components/form/FormModalHeader';
import { AssetsFields } from './AssetsFields';
import { AssetsImage } from './AssetsImage';
import { useAssetAlerts } from '../hooks/useAssetAlerts';
import { formatIsoDateForInput } from '../functions/formatIsoDateForInput';

interface Props {
  update: DispatchWithoutAction;
  asset: Asset;
  setAsset: Dispatch<Asset>;
}

export const AssetsEdit: FC<Props> = ({ update, asset, setAsset }) => {
  const { t } = useTranslation();
  const form = useAssetsEditForm(asset);
  const [alerts] = useAssetAlerts(asset);
  const { onHide } = useContext(ModalContext);
  const save = useSaveItem<AssetDto>((data) =>
    api({
      url: ApiPath.Asset.replace(':id', asset.id.toString()),
      method: 'patch',
      data: createAssetFormData(data),
    }),
  );
  const onSubmit = (data: AssetDto) => {
    save(data).then(() => {
      update();
      onHide();
    });
  };

  useEffect(() => {
    form.setValue(
      'alerts',
      alerts.map((alert) => ({
        id: alert.id,
        message: alert.message,
        period: alert.period,
        startedAt: formatIsoDateForInput(alert.startedAt),
      })),
    );
  }, [form, alerts]);

  return (
    <FormProvider {...form}>
      <Form onSubmit={form.handleSubmit(onSubmit)} encType="multipart/form-data">
        <FormModalHeader>{t('Edit asset')}</FormModalHeader>

        <Modal.Body>
          <AssetsFields image={asset.imageUrl && <AssetsImage update={update} asset={asset} setAsset={setAsset} />} />
        </Modal.Body>

        <FormModalFooter />
      </Form>
    </FormProvider>
  );
};
