import { FC, useState } from 'react';
import { DashboardTableSelect } from './DashboardTableSelect';
import { DashboardTableCancelButton } from './DashboardTableCancelButton';

export const DashboardTableCustomerSelect: FC<{ caption: string }> = ({ caption }) => {
  const [changed, setChanged] = useState(false);

  return changed ? (
    <DashboardTableCancelButton
      onClick={(event) => {
        event.stopPropagation();

        setChanged(false);
      }}
    />
  ) : (
    <DashboardTableSelect
      caption={caption}
      onChange={() => {
        setChanged(true);
      }}
    />
  );
};
