import { createContext, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useFormControlId } from '../../hooks/useFormControlId';
import { UseFormRegisterReturn } from 'react-hook-form/dist/types/form';
import { FieldError } from 'react-hook-form/dist/types/errors';

export interface FormFieldContextValue {
  name: string;
  controlId: string;
  invalid: boolean;
  error?: FieldError;
  controlAttrs: UseFormRegisterReturn<string>;
}

export const FormFieldContext = createContext<FormFieldContextValue>({
  name: '',
  controlId: '',
  invalid: false,
  controlAttrs: {} as UseFormRegisterReturn<string>,
});

export function useFormFieldContext(name: string): FormFieldContextValue {
  const controlId = useFormControlId();
  const { register, formState } = useFormContext();
  // @ts-ignore
  const error: FieldError | undefined = formState.errors[name];
  const attrs = useMemo<UseFormRegisterReturn>(() => register(name), [name, register]);

  return useMemo(
    () => ({
      controlAttrs: attrs,
      name,
      controlId,
      error,
      invalid: !!error,
    }),
    [attrs, name, controlId, error],
  );
}
