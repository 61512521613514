import React, { FC, useContext } from 'react';
import { NotificationsListItem } from './NotificationsListItem';
import { NotificationsEmpty } from './NotificationsEmpty';
import { useTypeNotifications } from './hooks/useTypeNotifications';
import { NotificationsPopupContext } from './NotificationsPopupContext';
import './NotificationsList.scss';

export const NotificationsList: FC = () => {
  const { type } = useContext(NotificationsPopupContext);
  const notifications = useTypeNotifications(type);

  return notifications.length > 0 ? (
    <ul className="NotificationsList">
      {notifications.map((notification, index) => (
        <NotificationsListItem notification={notification} key={index} />
      ))}
    </ul>
  ) : (
    <NotificationsEmpty />
  );
};
