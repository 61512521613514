import { FC } from 'react';
import { DashboardChart } from './DashboardChart';
import { useTranslation } from 'react-i18next';
import { Bar } from 'react-chartjs-2';
import { DashboardChartSelect } from './DashboardChartSelect';
import { colors } from '../../colors';

const labels = [
  'גלגלת מתח עליון',
  'גלגלת 400',
  'גלגלת מתח גבוה הרמה',
  'גלגלת ACS',
  'חמור אפור',
  'חמור אפור עם סדרן',
  'סולרית',
  'מגרר תאורה',
  'זק גדול חדש',
  'עגלת לחיצה',
  'מיגונית',
  'גנרטור',
];

export const DashboardIssuedChart: FC = () => {
  const { t } = useTranslation();

  return (
    <DashboardChart
      className="DashboardIssued"
      title={t('Issued assets').toString()}
      filter={
        <DashboardChartSelect>
          <option value="">
            {t('Customer')}, {t('Customer')}
          </option>
        </DashboardChartSelect>
      }
    >
      <Bar
        options={{
          plugins: {
            legend: {
              display: false,
            },
          },
        }}
        data={{
          labels: labels,
          datasets: [
            {
              label: t('Assets').toString(),
              data: labels.map(() => Math.round(Math.random() * 100)),
              backgroundColor: colors,
            },
          ],
        }}
      />
    </DashboardChart>
  );
};
