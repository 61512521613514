import { useMemo } from 'react';

export function useAdminTablePaginatedItems<T>(items: T[], currentPage: number, pageSize: any) {
  return useMemo(() => {
    const from = currentPage * pageSize - pageSize;
    const to = from + pageSize;

    return items ? items.slice(from, to) : [];
  }, [items, currentPage, pageSize]);
}
