import axios, { AxiosError, AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import { apiUrl } from '../constants';
import { SessionStorageKey } from '../enums/SessionStorageKey';
import { cleanLoginData } from './cleanLoginData';
import { storeLoginRedirectPath } from './storeLoginRedirectPath';
import { redirect } from 'react-router-dom';
import { RoutePath } from '../enums/RoutePath';

export const axiosInstance: AxiosInstance = axios.create({
  baseURL: apiUrl,
});

axiosInstance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  const token = sessionStorage.getItem(SessionStorageKey.Token);

  if (token) {
    config.headers.set('Authorization', `Bearer ${token}`);
  }

  return config;
});

axiosInstance.interceptors.response.use(undefined, async (error: AxiosError): Promise<any> => {
  if (error.response?.status === 401) {
    cleanLoginData();
    storeLoginRedirectPath();
    redirect(RoutePath.Login);
  }

  return Promise.reject(error);
});
