import { Notification } from '../interfaces/Notification';
import { api } from '../../functions/api';
import { ApiPath } from '../../enums/ApiPath';

export function getNotifications(afterId?: number): Promise<Notification[]> {
  return api({
    url: ApiPath.Notifications,
    params: {
      afterId,
    },
  });
}
