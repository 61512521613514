import React, { FC, useContext } from 'react';
import { NotificationsHeaderTitle } from './NotificationsHeaderTitle';
import { NotificationsHeaderReadAllButton } from './NotificationsHeaderReadAllButton';
import { NotificationsContext } from './NotificationsContext';
import './NotificationsHeader.scss';

export const NotificationsHeader: FC = () => {
  const { unreadCount } = useContext(NotificationsContext);

  return (
    <div className="NotificationsHeader">
      <NotificationsHeaderTitle />

      {unreadCount > 0 && <NotificationsHeaderReadAllButton />}
    </div>
  );
};
