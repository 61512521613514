import React, { useMemo } from 'react';
import { createStringFilterFunction } from '../../components/table/functions/createStringFilterFunction';
import { AdminTableInputFilter } from '../../components/table/filters/AdminTableInputFilter';
import { AdminTableColumn } from '../../components/table/interfaces/AdminTableColumn';
import { User } from '../dto/User';
import { useTranslation } from 'react-i18next';
import { AdminDate } from '../../components/table/AdminDate';
import { createDateFilterFunction } from '../../components/table/functions/createDateFilterFunction';
import { AdminTableDateFilter } from '../../components/table/filters/AdminTableDateFilter';

export function useUserColumns(): AdminTableColumn<User>[] {
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        title: t('Email'),
        predicate: (item) => item.email,
        sortingPredicate: (item) => item.email,
        filterConfig: {
          filter: createStringFilterFunction((item) => item.email),
          component: AdminTableInputFilter,
        },
      },
      {
        title: t('Created at'),
        predicate: (item) => <AdminDate date={item.createdAt} />,
        sortingPredicate: (item) => item.createdAt,
        filterConfig: {
          filter: createDateFilterFunction((item) => item.createdAt),
          component: AdminTableDateFilter,
        },
        style: {
          width: 160,
        },
      },
    ],
    [t],
  );
}
