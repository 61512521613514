import React, { useContext } from 'react';
import { Form } from 'react-bootstrap';
import { FormFieldContext } from '../components/form/FormFieldContext';
import { useCustomers } from '../hooks/useCustomers';

export function CustomerFormField() {
  const { controlId, controlAttrs, invalid } = useContext(FormFieldContext);
  const [customers] = useCustomers();

  return (
    <Form.Select {...controlAttrs} isInvalid={invalid} id={controlId}>
      <option value=""></option>

      {customers.map((customer) => (
        <option value={customer.id} key={customer.id}>
          {customer.name}
        </option>
      ))}
    </Form.Select>
  );
}
