import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  ReactElement,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

export interface TableSelectContextValue<T> {
  allItems: T[];
  selectedItems: T[];
  setSelectedItems: Dispatch<SetStateAction<T[]>>;
}

export const TableSelectContext = createContext<TableSelectContextValue<any>>({
  allItems: [],
  selectedItems: [],
  setSelectedItems() {},
});

export function useTableSelectContextValue<T>(allItems: T[]): TableSelectContextValue<T> {
  const [selectedItems, setSelectedItems] = useState<T[]>([]);

  useEffect(() => {
    setSelectedItems([]);
  }, [allItems]);

  return useMemo(
    () => ({
      allItems,
      selectedItems,
      setSelectedItems,
    }),
    [allItems, selectedItems, setSelectedItems],
  );
}

export function useTableSelectContext<T>(): TableSelectContextValue<T> {
  return useContext(TableSelectContext);
}

export function TableSelectContextProvider<T>({
  children,
  allItems,
}: PropsWithChildren & {
  allItems: T[];
}): ReactElement {
  return (
    <TableSelectContext.Provider value={useTableSelectContextValue(allItems)}>{children}</TableSelectContext.Provider>
  );
}
