import React, { DispatchWithoutAction, FC, useContext } from 'react';
import { Form } from 'react-bootstrap';
import { useCustomersCreateForm } from './hooks/useCustomersCreateForm';
import { FormProvider } from 'react-hook-form';
import ModalContext from 'react-bootstrap/ModalContext';
import { CustomerDto } from './dto/CustomerDto';
import { api } from '../functions/api';
import { ApiPath } from '../enums/ApiPath';
import { useSaveItem } from '../hooks/useSaveItem';
import { useTranslation } from 'react-i18next';
import { FormModalFooter } from '../components/form/FormModalFooter';
import { FormModalHeader } from '../components/form/FormModalHeader';
import { CustomerFormBody } from './CustomerFormBody';

interface Props {
  update: DispatchWithoutAction;
}

export const CustomersCreate: FC<Props> = ({ update }) => {
  const { t } = useTranslation();
  const form = useCustomersCreateForm();
  const { onHide } = useContext(ModalContext);
  const save = useSaveItem<CustomerDto>((data) =>
    api({
      url: ApiPath.Customers,
      method: 'post',
      data,
    }),
  );

  const onSubmit = (data: CustomerDto) => {
    save(data).then(() => {
      update();
      onHide();
    });
  };

  return (
    <FormProvider {...form}>
      <Form onSubmit={form.handleSubmit(onSubmit)}>
        <FormModalHeader>{t('Add new customer')}</FormModalHeader>
        <CustomerFormBody />
        <FormModalFooter />
      </Form>
    </FormProvider>
  );
};
