import React, { forwardRef, InputHTMLAttributes, Ref } from 'react';
import './TableSelectCheckbox.scss';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  inputClassName?: string;
}

export const TableSelectCheckbox = forwardRef(
  ({ inputClassName, className, children, ...attrs }: Props, ref: Ref<HTMLInputElement>) => {
    return (
      <label className="TableSelectCheckbox">
        <input className="input form-check-input" type="checkbox" {...attrs} ref={ref} />
      </label>
    );
  },
);
