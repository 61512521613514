import React, { FC, useCallback, useContext, useState } from 'react';
import { Notification } from './interfaces/Notification';
import './NotificationsListReadButton.scss';
import { setNotificationsAsRead } from './functions/setNotificationsAsRead';
import { NotificationsContext } from './NotificationsContext';
import SvgCheck from '../components/icons/Check';
import { CircleLoadingAnimatedIcon } from '../components/icons/CircleLoadingAnimatedIcon';

interface Props {
  notification: Notification;
}

export const NotificationsListReadButton: FC<Props> = ({ notification: { id } }) => {
  const { setNotifications } = useContext(NotificationsContext);
  const [loading, setLoading] = useState(false);
  const onClick = useCallback(() => {
    setLoading(true);

    setNotificationsAsRead([id])
      .then(() =>
        setNotifications((notifications) =>
          notifications.map((notification) =>
            notification.id === id
              ? {
                  ...notification,
                  readAt: new Date().toISOString(),
                }
              : notification,
          ),
        ),
      )
      .finally(() => setLoading(false));
  }, [id, setNotifications]);

  return (
    <button className="NotificationsListReadButton" onClick={onClick} disabled={loading}>
      {loading ? <CircleLoadingAnimatedIcon /> : <SvgCheck />}
    </button>
  );
};
