import { DispatchWithoutAction, useEffect } from 'react';

export function useKeyDown(key: string, callback: DispatchWithoutAction) {
  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.key === key) {
        callback();
      }
    };

    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [key, callback]);
}
