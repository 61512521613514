import React, { FC } from 'react';
import { NotificationsHeader } from './NotificationsHeader';
import { NotificationsMenu } from './NotificationsMenu';
import { NotificationsPopupContext, useNotificationsPopupContext } from './NotificationsPopupContext';
import { NotificationsList } from './NotificationsList';
import { useNotificationPopupClose } from './hooks/useNotificationPopupClose';
import './NotificationsPopup.scss';

export const NotificationsPopup: FC = () => {
  const contextValue = useNotificationsPopupContext();
  const elementRef = useNotificationPopupClose();

  return (
    <NotificationsPopupContext.Provider value={contextValue}>
      <div className="NotificationsPopup" ref={elementRef}>
        <NotificationsHeader />
        <NotificationsMenu />
        <NotificationsList />
      </div>
    </NotificationsPopupContext.Provider>
  );
};
