import React, { FC, PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { RoutePath } from '../enums/RoutePath';
import { useAuthorized } from '../hooks/useAuthorized';
import { storeLoginRedirectPath } from '../functions/storeLoginRedirectPath';
import { Header } from './header/Header';

export const AppRoute: FC<PropsWithChildren> = ({ children }) => {
  const authorized = useAuthorized();

  if (authorized) {
    return (
      <>
        <Header />

        <div className="container">{children}</div>
      </>
    );
  }

  storeLoginRedirectPath();

  return <Navigate to={RoutePath.Login} />;
};
