import { SessionStorageKey } from '../enums/SessionStorageKey';
import { RoutePath } from '../enums/RoutePath';

export function getLoginRedirectPath() {
  const value = sessionStorage.getItem(SessionStorageKey.LoginRedirect);

  if (value) {
    localStorage.removeItem(SessionStorageKey.LoginRedirect);

    return value;
  }

  return RoutePath.Home;
}
