import React, { useContext } from 'react';
import { Form } from 'react-bootstrap';
import { FormFieldContext } from '../components/form/FormFieldContext';
import { AssetsCategoryList } from './AssetsCategoryList';
import { ApiPath } from '../enums/ApiPath';

interface Props {
  apiPath: ApiPath.AssetCategories | ApiPath.AssetSubcategories;
}

export function AssetsCategoryControl({ apiPath }: Props) {
  const { controlId, controlAttrs, invalid } = useContext(FormFieldContext);
  const listId = `${controlId}-datalist`;

  return (
    <>
      <Form.Control {...controlAttrs} list={listId} isInvalid={invalid} />

      <AssetsCategoryList listId={listId} apiPath={apiPath} />
    </>
  );
}
