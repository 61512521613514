import React, { ButtonHTMLAttributes, FC } from 'react';
import classNames from 'classnames';
import './AdminToolbarButton.scss';

export const AdminToolbarButton: FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({ className, children, ...attrs }) => {
  return (
    <button className={classNames('AdminToolbarButton btn btn-outline-primary', className)} {...attrs}>
      {children}
    </button>
  );
};
