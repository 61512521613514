import React, { FC, ReactNode } from 'react';
import './AdminTwoLines.scss';

interface Props {
  first: ReactNode;
  second?: ReactNode;
}

export const AdminTwoLines: FC<Props> = ({ first, second }) => {
  return (
    <div className="AdminTwoLines">
      <div className="first">{first}</div>

      {second && <div className="second">{second}</div>}
    </div>
  );
};
