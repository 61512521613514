import React, { FC, PropsWithChildren } from 'react';
import './HeaderLink.scss';
import { RoutePath } from '../../enums/RoutePath';
import { NavLink } from 'react-router-dom';

interface Props extends PropsWithChildren {
  routePath: RoutePath;
}

export const HeaderLink: FC<Props> = ({ routePath, children }) => {
  return (
    <NavLink className={({ isActive }) => (isActive ? 'HeaderLink -active' : 'HeaderLink')} to={routePath}>
      {children}
    </NavLink>
  );
};
