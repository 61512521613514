import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { AdminTableFilterProps } from '../interfaces/AdminTableFilterProps';
import { AdminTableContext } from '../contexts/AdminTableContext';

export function useAdminTableFilterState<T>(
  initialState: T | (() => T),
  { filter }: AdminTableFilterProps<T>,
): [T, Dispatch<SetStateAction<T>>] {
  const { setFilterFunctions } = useContext(AdminTableContext);
  const state = useState<T>(initialState);
  const [value] = state;

  useEffect(() => {
    if (value) {
      const filterFunction = (item: any) => filter(item, value);

      setFilterFunctions((items) => items.concat(filterFunction));

      return () => {
        setFilterFunctions((items) => items.filter((item) => item !== filterFunction));
      };
    }
  }, [value, filter, setFilterFunctions]);

  return state;
}
