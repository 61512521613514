import React, { FC, PropsWithChildren } from 'react';
import './LoginLayout.scss';
import SvgLock from '../../components/icons/Lock';

interface Props extends PropsWithChildren {
  title: string;
}

export const LoginLayout: FC<Props> = ({ title, children }) => {
  return (
    <div className="LoginLayout">
      <div className="container">
        <div className="icon">
          <SvgLock />
        </div>

        <h2 className="title">{title}</h2>

        <div className="content">{children}</div>
      </div>
    </div>
  );
};
