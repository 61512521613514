import React, { DispatchWithoutAction, FC, useContext } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useUserCreateForm } from './hooks/useUserCreateForm';
import { FormProvider } from 'react-hook-form';
import ModalContext from 'react-bootstrap/ModalContext';
import { UserCreateDto } from './dto/UserCreateDto';
import { api } from '../functions/api';
import { ApiPath } from '../enums/ApiPath';
import { useSaveItem } from '../hooks/useSaveItem';
import { useTranslation } from 'react-i18next';
import { FormModalFooter } from '../components/form/FormModalFooter';
import { FormModalHeader } from '../components/form/FormModalHeader';
import { UsersEmailField } from './UsersEmailField';
import { UsersPasswordField } from './UsersPasswordField';

interface Props {
  update: DispatchWithoutAction;
}

export const UsersCreate: FC<Props> = ({ update }) => {
  const { t } = useTranslation();
  const form = useUserCreateForm();
  const { onHide } = useContext(ModalContext);
  const save = useSaveItem<UserCreateDto>((data) =>
    api({
      url: ApiPath.Users,
      method: 'post',
      data,
    }),
  );

  const onSubmit = (data: UserCreateDto) => {
    save(data).then(() => {
      update();
      onHide();
    });
  };

  return (
    <FormProvider {...form}>
      <Form onSubmit={form.handleSubmit(onSubmit)}>
        <FormModalHeader>{t('Add new user')}</FormModalHeader>

        <Modal.Body>
          <UsersEmailField />
          <UsersPasswordField />
        </Modal.Body>

        <FormModalFooter />
      </Form>
    </FormProvider>
  );
};
