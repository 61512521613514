import React, { FC } from 'react';
import SvgCircleLoading from './CircleLoading';
import './CircleLoadingAnimatedIcon.scss';

export const CircleLoadingAnimatedIcon: FC = () => {
  return (
    <div className="CircleLoadingAnimatedIcon">
      <SvgCircleLoading />
    </div>
  );
};
