import React, { FC } from 'react';
import { Notification } from './interfaces/Notification';
import './NotificationsListItem.scss';
import { NotificationsListContent } from './NotificationsListContent';
import { NotificationsListReadButton } from './NotificationsListReadButton';

interface Props {
  notification: Notification;
}

export const NotificationsListItem: FC<Props> = ({ notification }) => {
  return (
    <li className="NotificationsListItem">
      <NotificationsListContent notification={notification} />

      {notification.readAt === null && <NotificationsListReadButton notification={notification} />}
    </li>
  );
};
