import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, UseFormReturn } from 'react-hook-form';
import * as yup from 'yup';
import { LoginFormDto } from '../LoginFormDto';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

export function useLoginForm(): UseFormReturn<LoginFormDto> {
  const { t } = useTranslation();

  return useForm<LoginFormDto>({
    resolver: yupResolver(
      useMemo(
        () =>
          yup.object().shape({
            email: yup.string().email().label(t('Email')).required(),
            password: yup.string().label(t('Password')).required(),
          }),
        [t],
      ),
    ),
  });
}
