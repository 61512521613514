import * as React from 'react';
import type { SVGProps } from 'react';
const SvgExternal = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 32 32" {...props}>
    <g fill="currentColor" fillRule="evenodd" clipPath="url(#external_svg__a)" clipRule="evenodd">
      <path d="M26.8 31.875H5.262A5.08 5.08 0 0 1 .188 26.8V5.262A5.08 5.08 0 0 1 5.261.188h9.027a1.997 1.997 0 1 1 0 3.995H5.262a1.08 1.08 0 0 0-1.08 1.08V26.8c0 .596.486 1.08 1.08 1.08H26.8a1.08 1.08 0 0 0 1.08-1.08v-9.027a1.997 1.997 0 1 1 3.995 0V26.8a5.08 5.08 0 0 1-5.075 5.075Z" />
      <path d="M21.613 0h8.395c1.134 0 2.055.919 2.055 2.055v8.395a2.056 2.056 0 0 1-3.508 1.453L25.81 9.157l-9.74 9.74a2.051 2.051 0 0 1-2.905 0 2.053 2.053 0 0 1 0-2.906l9.74-9.74-2.745-2.744A2.055 2.055 0 0 1 21.613 0Z" />
    </g>
    <defs>
      <clipPath id="external_svg__a">
        <path fill="currentColor" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgExternal;
