import React, { FC } from 'react';
import { Form } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import { useAssetPacks } from './hooks/useAssetPacks';
import { useAssetsPackSearchParam } from './hooks/useAssetsPackSearchParam';
import { useTranslation } from 'react-i18next';
import { RouteUrlParam } from '../enums/RouteUrlParam';

export const AssetsFilterPack: FC = () => {
  const { t } = useTranslation();
  const [, setSearchParams] = useSearchParams();
  const [assetPacks] = useAssetPacks();
  const value = useAssetsPackSearchParam();

  if (assetPacks.length === 0) {
    return null;
  }

  return (
    <div className="AssetsFilterPack">
      <Form.Select
        onChange={({ target: { value } }) =>
          setSearchParams(
            value
              ? {
                  [RouteUrlParam.Pack]: value,
                }
              : {},
          )
        }
        value={value}
      >
        <option value="">{t('Select a pack')}</option>

        {assetPacks.map((assetPack) => (
          <option value={assetPack.id} key={assetPack.id}>
            {assetPack.name}
          </option>
        ))}
      </Form.Select>
    </div>
  );
};
