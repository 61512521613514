import * as React from 'react';

function SvgCircleLoading(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16 32C7.178 32 0 24.822 0 16h4c0 6.617 5.383 12 12 12s12-5.383 12-12S22.617 4 16 4V0c8.822 0 16 7.178 16 16s-7.178 16-16 16z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgCircleLoading;
