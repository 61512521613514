import * as React from 'react';

function SvgLogout(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.78 28.163h10.43a1.16 1.16 0 001.16-1.16V6.144a1.16 1.16 0 00-1.16-1.16H15.78a1.16 1.16 0 010-2.317h10.43a3.48 3.48 0 013.467 3.466v20.872a3.48 3.48 0 01-3.467 3.467H15.78a1.16 1.16 0 110-2.317v.008z"
        fill="currentColor"
      />
      <path
        d="M2.125 15.755L9.17 8.8a1.16 1.16 0 011.627 1.65l-5.035 4.971h13.493a1.16 1.16 0 110 2.318H5.762l5.035 4.97A1.16 1.16 0 119.17 24.36l-7.045-6.955a1.157 1.157 0 010-1.65z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgLogout;
