import React, { FC, useContext, useMemo } from 'react';
import { AdminTableColumn } from './interfaces/AdminTableColumn';
import { useTableColumnSortingDirection } from './hooks/useTableColumnSortingDirection';
import { useTableColumnSortingToggle } from './hooks/useTableColumnSortingToggle';
import './AdminTableHeadColumnButton.scss';
import { TableSortingDirection } from './enums/TableSortingDirection';
import { AdminTableContext } from './contexts/AdminTableContext';
import SvgSortAsc from '../icons/SortAsc';
import SvgSortAsc1 from '../icons/SortAsc1';
import SvgSortAsc2 from '../icons/SortAsc2';
import SvgSortDesc from '../icons/SortDesc';
import SvgSortDesc1 from '../icons/SortDesc1';
import SvgSortDesc2 from '../icons/SortDesc2';

interface Props<T> {
  column: AdminTableColumn<T>;
}

function useAdminTableFirstSortingColumn() {
  const { columnDirections } = useContext(AdminTableContext);
  return useMemo(() => columnDirections.keys().next()?.value, [columnDirections]);
}

export const AdminTableHeadColumnButton: FC<Props<any>> = ({ column }) => {
  const { columnDirections } = useContext(AdminTableContext);
  const direction = useTableColumnSortingDirection(column);
  const toggle = useTableColumnSortingToggle(column);
  const firstSortingColumn = useAdminTableFirstSortingColumn();

  return (
    <button className="AdminTableHeadColumnButton" onClick={toggle}>
      {column.title}

      {direction === TableSortingDirection.Asc ? (
        columnDirections.size === 1 ? (
          <SvgSortAsc className="icon" />
        ) : firstSortingColumn === column ? (
          <SvgSortAsc1 className="icon" />
        ) : (
          <SvgSortAsc2 className="icon" />
        )
      ) : direction === TableSortingDirection.Desc ? (
        columnDirections.size === 1 ? (
          <SvgSortDesc className="icon" />
        ) : firstSortingColumn === column ? (
          <SvgSortDesc1 className="icon" />
        ) : (
          <SvgSortDesc2 className="icon" />
        )
      ) : (
        <SvgSortAsc className="icon -transparent" />
      )}
    </button>
  );
};
