import { useMemo } from 'react';
import { Notification } from '../interfaces/Notification';
import { NotificationAction } from '../interfaces/NotificationAction';
import { RoutePath } from '../../enums/RoutePath';
import { RouteUrlParam } from '../../enums/RouteUrlParam';
import { useTranslation } from 'react-i18next';

export function useNotificationActions({ assetPackId }: Notification): NotificationAction[] {
  const { t } = useTranslation();

  return useMemo(() => {
    const actions: NotificationAction[] = [];

    if (assetPackId) {
      actions.push({
        title: t('Show assets'),
        routePath: RoutePath.Assets + `?${RouteUrlParam.Pack}=${assetPackId}`,
      });
    }

    return actions;
  }, [assetPackId, t]);
}
