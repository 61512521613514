import { useMemo } from 'react';
import { orderBy } from 'lodash';
import { AdminTableColumn } from '../interfaces/AdminTableColumn';
import { TableSortingDirection } from '../enums/TableSortingDirection';

export function useAdminTableSortedItems<T>(
  items: T[],
  columnDirections: Map<AdminTableColumn<T>, TableSortingDirection>,
) {
  return useMemo(() => {
    if (columnDirections.size === 0) {
      return items;
    }

    const predicates: ((item: T) => unknown)[] = [];
    const directions: TableSortingDirection[] = [];

    Array.from(columnDirections.entries()).forEach(([{ sortingPredicate }, direction]) => {
      if (Array.isArray(sortingPredicate)) {
        sortingPredicate.forEach((predicate) => {
          predicates.push(predicate);
          directions.push(direction);
        });
      } else if (sortingPredicate) {
        predicates.push(sortingPredicate);
        directions.push(direction);
      }
    });

    return orderBy<T[]>(items, predicates, directions) as T[];
  }, [items, columnDirections]);
}
