export enum ApiPath {
  AuthLogin = 'auth/login',
  AuthTemporaryToken = 'auth/temporary-token',
  Assets = 'assets',
  AssetsAssign = 'assets/assign',
  Asset = 'assets/:id',
  AssetPacks = 'asset-packs',
  AssetAlerts = 'assets/:id/alerts',
  AssetAlert = 'assets/alerts/:id',
  AssetImage = 'assets/:id/image',
  AssetDuplicate = 'assets/:id/duplicate',
  AssetCategories = 'assets/categories',
  AssetSubcategories = 'assets/subcategories',
  Customers = 'customers',
  Customer = 'customers/:id',
  Users = 'users',
  User = 'users/:id',
  UserPassword = 'users/:id/password',
  Transactions = 'transactions',
  Transaction = 'transactions/:id',
  Notifications = '/notifications',
  NotificationsStatusRead = '/notifications/status/read',
}
