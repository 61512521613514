import { Dispatch, MutableRefObject, useEffect, useRef } from 'react';

export function useOnOutside(
  elementRef: MutableRefObject<HTMLDivElement | null>,
  callback: Dispatch<MouseEvent | TouchEvent>,
): MutableRefObject<HTMLDivElement | null> {
  const callbackRef = useRef<Dispatch<MouseEvent | TouchEvent>>(callback);

  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      if (elementRef.current?.contains(event.target as Node)) {
        return;
      }

      callbackRef.current(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [elementRef, callbackRef]);

  return elementRef;
}
