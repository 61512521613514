import * as React from 'react';

function SvgSortDesc(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.724 20.057c.52-.52 1.365-.52 1.885 0L8 24.447l4.39-4.39a1.333 1.333 0 111.886 1.886l-5.333 5.333c-.52.52-1.365.52-1.886 0l-5.333-5.333a1.333 1.333 0 010-1.886z"
        fill="currentColor"
      />
      <path
        d="M8 5c.736 0 1.333.597 1.333 1.333v20a1.333 1.333 0 11-2.666 0v-20C6.667 5.597 7.264 5 8 5z"
        fill="currentColor"
      />
      <rect x={14} y={8} width={14} height={2.67} rx={1.335} fill="currentColor" />
      <rect x={16} y={14.67} width={12} height={2.67} rx={1.335} fill="currentColor" />
      <rect x={18} y={21.34} width={10} height={2.67} rx={1.335} fill="currentColor" />
    </svg>
  );
}

export default SvgSortDesc;
