import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export function useAssetFromResolver() {
  const { t } = useTranslation();

  return useMemo(
    () =>
      yupResolver(
        yup.object().shape({
          title: yup.string().label(t('Title')).required(),
          guid: yup.string().label(t('GUID')).required(),
          category: yup.string().nullable().label(t('Category')),
          subcategory: yup.string().nullable().label(t('Subcategory')),
          notes: yup.string().nullable().label(t('Notes')),
          image: yup
            .mixed<FileList>()
            .nullable()
            .test({
              test: (files?: FileList | null) => {
                if (files?.length) {
                  const extension = files.item(0)?.name.match(/\.(\w+)$/)?.[1];
                  const extensions = ['jpg', 'jpeg', 'png', 'gif'];

                  return !!(extension && extensions.includes(extension));
                } else {
                  return true;
                }
              },
              message: t('Only jpg, png, gif image types are allowed').toString(),
            })
            .label(t('Image')),
          alerts: yup.array().of(
            yup.object().shape({
              id: yup.number().optional(),
              message: yup.string().label(t('Message')).required(),
              period: yup
                .number()
                .label(t('Period'))
                .transform((value) => {
                  const float = parseFloat(value);

                  return isNaN(float) ? 0 : float;
                })
                .min(1)
                .required(),
              startedAt: yup.string().label(t('Started at')).required(),
            }),
          ),
        }),
      ),
    [t],
  );
}
