import React, { FC } from 'react';
import { AdminTable } from '../components/table/AdminTable';
import { ApiPath } from '../enums/ApiPath';
import { AdminToolbar } from '../components/toolbar/AdminToolbar';
import { AdminToolbarTitle } from '../components/toolbar/AdminToolbarTitle';
import { Transaction } from './dto/Transaction';
import { useTransactionColumns } from './hooks/useTransactionColumns';

import { useApiItems } from '../hooks/useApiItems';
import { useTranslation } from 'react-i18next';

export const Transactions: FC = () => {
  const { t } = useTranslation();
  const columns = useTransactionColumns();
  const [items, loading] = useApiItems<Transaction>(ApiPath.Transactions);

  return (
    <>
      <AdminToolbar>
        <AdminToolbarTitle>{t('Transactions')}</AdminToolbarTitle>
      </AdminToolbar>

      <AdminTable items={items} loading={loading} columns={columns} />
    </>
  );
};
