import React, { FC, useCallback, useContext, useState } from 'react';
import { setNotificationsAsRead } from './functions/setNotificationsAsRead';
import { NotificationsContext } from './NotificationsContext';
import './NotificationsHeaderReadAllButton.scss';
import { useTranslation } from 'react-i18next';

export const NotificationsHeaderReadAllButton: FC = () => {
  const { notifications, setNotifications } = useContext(NotificationsContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const onClick = useCallback(() => {
    const unreadIds = notifications
      .filter((notification) => notification.readAt === null)
      .map((notification) => notification.id);

    setLoading(true);

    setNotificationsAsRead(unreadIds)
      .then(() =>
        setNotifications((notifications) =>
          notifications.map((notification) =>
            notification.readAt
              ? notification
              : {
                  ...notification,
                  readAt: new Date().toISOString(),
                },
          ),
        ),
      )
      .finally(() => setLoading(false));
  }, [notifications, setNotifications]);

  return (
    <button className="NotificationsHeaderReadAllButton" onClick={onClick} disabled={loading}>
      {loading ? t('Loading') : t('Mark all as read')}
    </button>
  );
};
