import { useApiData } from '../../hooks/useApiData';
import { useMemo } from 'react';
import { ApiPath } from '../../enums/ApiPath';

export function useAssetCategories(apiPath: ApiPath.AssetCategories | ApiPath.AssetSubcategories) {
  const [categories] = useApiData<string[]>(
    useMemo(
      () => ({
        url: apiPath,
      }),
      [apiPath],
    ),
    [],
  );
  return categories;
}
