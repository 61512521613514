import { ButtonHTMLAttributes, FC, ReactNode } from 'react';
import classNames from 'classnames';
import './DashboardButton.scss';

export const DashboardButton: FC<
  ButtonHTMLAttributes<HTMLButtonElement> & {
    caption: ReactNode;
    icon: ReactNode;
  }
> = ({ className, children, caption, icon, ...attrs }) => {
  return (
    <button className={classNames('DashboardButton', className)} {...attrs}>
      <span className="icon">{icon}</span>

      <span className="content">
        <span className="title">{children}</span>
        <span className="caption">{caption}</span>
      </span>
    </button>
  );
};
