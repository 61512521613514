import { useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, UseFormReturn } from 'react-hook-form';
import * as yup from 'yup';
import { UserCreateDto } from '../dto/UserCreateDto';
import { useTranslation } from 'react-i18next';

export function useUserCreateForm(): UseFormReturn<UserCreateDto> {
  const { t } = useTranslation();

  return useForm({
    resolver: useMemo(
      () =>
        yupResolver(
          yup.object().shape({
            email: yup.string().email().label(t('Email')).required(),
            password: yup.string().label(t('Password')).required(),
          }),
        ),
      [t],
    ),
  });
}
