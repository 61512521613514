import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import './NotificationsListActions.scss';
import { NotificationAction } from './interfaces/NotificationAction';

interface Props {
  actions: NotificationAction[];
}

export const NotificationsListActions: FC<Props> = ({ actions }) => {
  return (
    <div className="NotificationsListActions">
      {actions.map(({ title, onClick, routePath, url }, index) =>
        url ? (
          <a className="control" href={url} onClick={onClick} key={index}>
            {title}
          </a>
        ) : routePath ? (
          <Link className="control" to={routePath} onClick={onClick} key={index}>
            {title}
          </Link>
        ) : (
          <button className="control" onClick={onClick} key={index}>
            {title}
          </button>
        ),
      )}
    </div>
  );
};
