import React, { FC } from 'react';
import './NotificationsMenuCounter.scss';
import classNames from 'classnames';

interface Props {
  count: number;
  active: boolean;
}

export const NotificationsMenuCounter: FC<Props> = ({ count, active }) => {
  return (
    <div
      className={classNames('NotificationsMenuCounter', {
        '-active': active,
      })}
    >
      {count}
    </div>
  );
};
