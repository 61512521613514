import { useMemo } from 'react';
import { AdminTableFilterFunction } from '../interfaces/AdminTableFilterFunction';

export function useAdminTableFilteredItems<T>(items: T[], filterFunctions: AdminTableFilterFunction<T>[]) {
  return useMemo(() => {
    if (filterFunctions.length === 0) {
      return items;
    }

    return items.filter((item) => filterFunctions.every((filterFunction) => filterFunction(item)));
  }, [items, filterFunctions]);
}
