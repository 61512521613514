import React, { FC, useEffect, useRef } from 'react';
import { useTableSelectContext } from './TableSelectContext';
import { TableSelectCheckbox } from './TableSelectCheckbox';

export const TableSelectAll: FC = () => {
  const { setSelectedItems, allItems, selectedItems } = useTableSelectContext();
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const input = inputRef.current;

    if (input) {
      if (selectedItems.length === 0 || allItems.length === 0) {
        input.checked = false;
        input.indeterminate = false;
      } else if (selectedItems.length !== 0 && selectedItems.length !== allItems.length) {
        input.checked = false;
        input.indeterminate = true;
      } else {
        input.checked = true;
        input.indeterminate = false;
      }
    }
  }, [allItems, selectedItems]);

  return (
    <TableSelectCheckbox
      onChange={() => {
        setSelectedItems((selectedItems) =>
          selectedItems.length === allItems.length ? [] : selectedItems.length === 0 ? allItems : [],
        );
      }}
      ref={inputRef}
    />
  );
};
