import { FC } from 'react';
import { DashboardButton } from './DashboardButton';
import './DashboardButtons.scss';
import { ArrowDownFromLine, ArrowUpFromLine } from 'lucide-react';
import { useTranslation } from 'react-i18next';

export const DashboardButtons: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="DashboardButtons">
      <DashboardButton caption={t('Assign an asset to a customer')} icon={<ArrowUpFromLine />}>
        {t('Issue assets')}
      </DashboardButton>

      <DashboardButton caption={t('Unassign an asset from a customer')} icon={<ArrowDownFromLine />}>
        {t('Return assets')}
      </DashboardButton>
    </div>
  );
};
