import { DispatchWithoutAction, useEffect } from 'react';

export function useInterval(callback: DispatchWithoutAction, delay: number, before = true) {
  useEffect(() => {
    const interval = setInterval(callback, delay);

    if (before) {
      callback();
    }

    return () => clearInterval(interval);
  }, [callback, delay, before]);
}
