import { useForm, UseFormReturn } from 'react-hook-form';
import { AssetDto } from '../dto/AssetDto';
import { Asset } from '../dto/Asset';
import { useAssetFromResolver } from './useAssetFromResolver';

export function useAssetsEditForm(asset: Asset): UseFormReturn<AssetDto> {
  const resolver = useAssetFromResolver();

  return useForm<AssetDto>({
    resolver,
    defaultValues: {
      title: asset.title,
      guid: asset.guid,
      category: asset.category,
      subcategory: asset.subcategory,
      notes: asset.notes,
      image: null,
    },
  });
}
