import React, { FC } from 'react';
import { Asset } from './dto/Asset';
import { AdminTwoLines } from '../components/table/AdminTwoLines';
import { AdminIsoDate } from '../components/table/AdminIsoDate';

interface Props {
  item: Asset;
}

export const AssetsTakenColumn: FC<Props> = ({ item }) => {
  if (item.takenBy) {
    return <AdminTwoLines first={item.takenBy} second={<AdminIsoDate isoDate={item.takenAt} />}></AdminTwoLines>;
  }

  return <>-</>;
};
