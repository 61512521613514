import { useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, UseFormReturn } from 'react-hook-form';
import * as yup from 'yup';
import { UserEditDto } from '../dto/UserEditDto';
import { User } from '../dto/User';
import { useTranslation } from 'react-i18next';

export function useUserEditForm(user: User): UseFormReturn<UserEditDto> {
  const { t } = useTranslation();

  return useForm({
    resolver: useMemo(
      () =>
        yupResolver(
          yup.object().shape({
            email: yup.string().email().label(t('Email')).required(),
          }),
        ),
      [t],
    ),
    defaultValues: {
      email: user.email,
    },
  });
}
