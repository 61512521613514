import React, { Dispatch, DispatchWithoutAction, FC } from 'react';
import { Figure } from 'react-bootstrap';
import { Asset } from './dto/Asset';
import { api } from '../functions/api';
import { ApiPath } from '../enums/ApiPath';
import { apiUrl } from '../constants';
import { useDeleteItem } from '../hooks/useDeleteItem';
import { useTranslation } from 'react-i18next';

interface Props {
  update: DispatchWithoutAction;
  asset: Asset;
  setAsset: Dispatch<Asset>;
}

export const AssetsImage: FC<Props> = ({ update, asset, setAsset }) => {
  const { t } = useTranslation();
  const deleteImage = useDeleteItem<void>(() =>
    api({
      url: ApiPath.AssetImage.replace(':id', asset.id.toString()),
      method: 'delete',
    }),
  );
  const onDeleteClick = () => {
    if (window.confirm(t('Are you sure you want to delete?').toString())) {
      deleteImage().then(() => {
        update();
        setAsset({
          ...asset,
          imageUrl: null,
        });
      });
    }
  };

  return (
    <Figure>
      <Figure.Image className="w-25" src={apiUrl + asset.imageUrl} thumbnail={true} />
      <Figure.Caption>
        <button className="btn btn-sm btn-outline-danger" type="button" onClick={onDeleteClick}>
          {t('Delete image')}
        </button>
      </Figure.Caption>
    </Figure>
  );
};
