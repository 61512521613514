import React, { FC, useContext } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { FormProvider } from 'react-hook-form';
import ModalContext from 'react-bootstrap/ModalContext';
import { api } from '../functions/api';
import { ApiPath } from '../enums/ApiPath';
import { useSaveItem } from '../hooks/useSaveItem';
import { User } from './dto/User';
import { useUserPasswordForm } from './hooks/useUserPasswordForm';
import { UserPasswordDto } from './dto/UserPasswordDto';
import { useTranslation } from 'react-i18next';
import { FormModalFooter } from '../components/form/FormModalFooter';
import { FormModalHeader } from '../components/form/FormModalHeader';
import { UsersPasswordField } from './UsersPasswordField';

interface Props {
  user: User;
}

export const UsersPassword: FC<Props> = ({ user }) => {
  const { t } = useTranslation();
  const form = useUserPasswordForm();
  const { onHide } = useContext(ModalContext);
  const save = useSaveItem<UserPasswordDto>((data) =>
    api({
      url: ApiPath.UserPassword.replace(':id', user.id.toString()),
      method: 'patch',
      data,
    }),
  );
  const onSubmit = (data: UserPasswordDto) => {
    save(data).then(() => {
      onHide();
    });
  };

  return (
    <FormProvider {...form}>
      <Form onSubmit={form.handleSubmit(onSubmit)}>
        <FormModalHeader>{t('Change password')}</FormModalHeader>

        <Modal.Body>
          <UsersPasswordField autoFocus={true} />
        </Modal.Body>

        <FormModalFooter />
      </Form>
    </FormProvider>
  );
};
