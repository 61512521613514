import React, { ChangeEvent, Dispatch, FC, useCallback, useMemo } from 'react';
import format from 'date-fns/format';
import { useFormControlId } from '../../hooks/useFormControlId';
import { isDateValid } from '../../functions/isDateValid';
import { Form } from 'react-bootstrap';

interface Props {
  label: string;
  value?: Date | null;
  onChange: Dispatch<Date | null>;
}

export const AdminFormDate: FC<Props> = ({ label, onChange, value }) => {
  const onInputChange = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      if (value) {
        const date = new Date(value);

        if (isDateValid(date)) {
          return onChange(date);
        }
      }

      onChange(null);
    },
    [onChange],
  );
  const controlId = useFormControlId();
  const defaultValue = useMemo(() => (value ? format(value, 'yyyy-MM-dd') : undefined), [value]);

  return (
    <Form.Group className="AdminFormDate mb-1">
      <Form.Label className="label m-0" htmlFor={controlId}>
        {label}
      </Form.Label>

      <Form.Control
        size="sm"
        type="date"
        onChange={onInputChange}
        id={controlId}
        defaultValue={defaultValue}
      />
    </Form.Group>
  );
};
