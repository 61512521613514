import React, { FC } from 'react';
import Dropdown from 'react-bootstrap/esm/Dropdown';
import './DropdownMenuItem.scss';
import { MenuItemProps } from '../../interfaces/MenuItemProps';

export const DropdownMenuItem: FC<MenuItemProps> = ({ title, routePath, onClick, icon }) => {
  return (
    <Dropdown.Item
      className="DropdownMenuItem"
      as={onClick && 'button'}
      href={routePath}
      onClick={onClick}
      role="listitem"
    >
      <span className="icon">{icon}</span>
      <span className="title">{title}</span>
    </Dropdown.Item>
  );
};
