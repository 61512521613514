import React, { Dispatch, DispatchWithoutAction, FC, SetStateAction, useCallback, useState } from 'react';
import { AdminFormDate } from '../AdminFormDate';
import { OptionalDateRange } from '../interfaces/OptionalDateRange';
import './AdminTableDateFilterPopup.scss';
import { useTranslation } from 'react-i18next';

interface Props {
  hide: DispatchWithoutAction;
  range: OptionalDateRange | null;
  setRange: Dispatch<SetStateAction<OptionalDateRange | null>>;
}

export const AdminTableDateFilterPopup: FC<Props> = ({ hide, range, setRange }) => {
  const { t } = useTranslation();
  const [from, setFrom] = useState<Date | null>(() => (range ? range[0] : null));
  const [to, setTo] = useState<Date | null>(() => (range ? range[1] : null));
  const apply = useCallback(() => {
    setRange(from || to ? [from, to] : null);
    hide();
  }, [from, to, setRange, hide]);
  const clear = useCallback(() => {
    setRange(null);
    hide();
  }, [setRange, hide]);

  return (
    <div className="AdminTableDateFilterPopup p-2">
      <AdminFormDate label={t('From')} onChange={setFrom} value={from} />
      <AdminFormDate label={t('To').toString()} onChange={setTo} value={to} />
      <button className="btn btn-sm btn-primary" onClick={apply}>
        {t('Apply')}
      </button>
      &nbsp;
      <button className="btn btn-sm btn-outline-primary" onClick={clear}>
        {t('Clear')}
      </button>
    </div>
  );
};
