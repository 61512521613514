import { Dispatch, useCallback } from 'react';
import { Asset } from '../assets/dto/Asset';
import { useOpenVilog } from './useOpenVilog';

export function useShowAssetOnMap(): Dispatch<Asset> {
  const openVilog = useOpenVilog();

  return useCallback(
    (asset: Asset) => {
      openVilog('/?vin=' + asset.guid);
    },
    [openVilog],
  );
}
