import { useCallback } from 'react';
import { SubmitHandler, UseFormReturn } from 'react-hook-form';
import { LoginFormDto } from '../LoginFormDto';
import { useNavigate } from 'react-router-dom';
import { getLoginRedirectPath } from '../../../functions/getLoginRedirectPath';
import { storeLoginData } from '../../../functions/storeLoginData';
import { login } from '../../../functions/login';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

export function useLoginSubmit(form: UseFormReturn<LoginFormDto>): SubmitHandler<LoginFormDto> {
  const { setError } = form;
  const navigate = useNavigate();
  const { t } = useTranslation();

  return useCallback(
    async (data) => {
      await login(data)
        .then(({ token }) => {
          storeLoginData({ email: data.email, token });
          navigate(getLoginRedirectPath());
        })
        .catch((error) => {
          if (error.response?.status === 403) {
            setError('email', {
              message: t('Email or password is incorrect').toString(),
            });
          } else {
            toast.error(t('An error has occurred'));
          }
        });
    },
    [setError, t, navigate],
  );
}
