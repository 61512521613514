import { useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, UseFormReturn } from 'react-hook-form';
import * as yup from 'yup';
import { UserPasswordDto } from '../dto/UserPasswordDto';
import { useTranslation } from 'react-i18next';

export function useUserPasswordForm(): UseFormReturn<UserPasswordDto> {
  const { t } = useTranslation();

  return useForm({
    resolver: useMemo(
      () =>
        yupResolver(
          yup.object().shape({
            password: yup.string().label(t('Password')).required(),
          }),
        ),
      [t],
    ),
  });
}
