import React, { DispatchWithoutAction, FC, PropsWithChildren } from 'react';
import './HeaderButton.scss';

interface Props extends PropsWithChildren {
  onClick: DispatchWithoutAction;
}

export const HeaderButton: FC<Props> = ({ onClick, children }) => {
  return (
    <button className="HeaderButton" onClick={onClick}>
      {children}
    </button>
  );
};
