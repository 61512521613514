import React, { TableHTMLAttributes, useMemo } from 'react';
import './AdminTable.scss';
import { AdminTableHead } from './AdminTableHead';
import { AdminTableBody } from './AdminTableBody';
import { AdminTableActionsItem } from './interfaces/AdminTableActionsItem';
import { AdminTableColumn } from './interfaces/AdminTableColumn';
import { AdminTableFilters } from './AdminTableFilters';
import classNames from 'classnames';
import { TablePagination } from './TablePagination';
import { AdminTableContext, useAdminTableContext } from './contexts/AdminTableContext';
import { AdminTableLoader } from './AdminTableLoader';
import { AdminTableStub } from './AdminTableStub';
import { AdminTableOverlap } from './AdminTableOverlap';
import { useTranslation } from 'react-i18next';

export interface AdminTableProps<T> extends TableHTMLAttributes<HTMLTableElement> {
  columns: AdminTableColumn<T>[];
  items: T[];
  actions?: AdminTableActionsItem<T>[];
  loading?: boolean;
}

export function AdminTable<T>({ columns, actions, items, className, loading, ...attrs }: AdminTableProps<T>) {
  const { t } = useTranslation();
  const contextValue = useAdminTableContext({ columns, actions, items, loading });
  const filtersDefined = useMemo(() => columns.some((column) => column.filterConfig), [columns]);

  return (
    <AdminTableContext.Provider value={contextValue}>
      <div className="AdminTable">
        <table className={classNames('table table-hover', { '-loading': loading }, className)} {...attrs}>
          <AdminTableHead />

          {filtersDefined && <AdminTableFilters />}

          <AdminTableBody />
        </table>

        {loading && items.length === 0 ? (
          <AdminTableStub>
            <AdminTableLoader />
          </AdminTableStub>
        ) : items.length === 0 ? (
          <AdminTableStub>{t('There is no such data')}</AdminTableStub>
        ) : contextValue.filteredItems.length === 0 ? (
          <AdminTableStub>{t('No matches found, please clear filters')}</AdminTableStub>
        ) : loading ? (
          <AdminTableOverlap>
            <AdminTableLoader />
          </AdminTableOverlap>
        ) : null}

        <TablePagination />
      </div>
    </AdminTableContext.Provider>
  );
}
