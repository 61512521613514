import React, { FC, memo, useCallback, useMemo } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import './AdminTableAction.scss';
import { AdminTableActionsItem } from './interfaces/AdminTableActionsItem';

interface Props<T> {
  item: T;
  action: AdminTableActionsItem<T>;
}

export const AdminTableAction: FC<Props<any>> = memo(({ action: { icon, title, onClick, isDisabled }, item }) => {
  const callback = useCallback(() => onClick(item), [onClick, item]);
  const disabled = useMemo(() => (isDisabled ? isDisabled(item) : false), [isDisabled, item]);
  const content = useMemo(() => (typeof title === 'function' ? title(item) : title), [title, item]);

  return (
    <Dropdown.Item className="AdminTableAction" as="button" onClick={callback} disabled={disabled}>
      {icon && <span className="icon">{icon}</span>}
      <span className="title">{content}</span>
    </Dropdown.Item>
  );
});
