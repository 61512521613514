import React, { FC, useContext, useMemo } from 'react';
import './AdminTableActions.scss';
import { AdminTableAction } from './AdminTableAction';
import { AdminTableContext } from './contexts/AdminTableContext';
import { Dropdown } from 'react-bootstrap';
import SvgThreeDotsVertical from '../icons/ThreeDotsVertical';
import { DropdownMenuToggle } from '../dropdown/DropdownMenuToggle';

interface Props<T> {
  item: T;
}

export const AdminTableActions: FC<Props<any>> = ({ item }) => {
  const { actions } = useContext(AdminTableContext);
  const visibleActions = useMemo(
    () => actions?.filter((action) => !action.isInvisible || !action.isInvisible(item)),
    [actions, item],
  );

  return (
    <div className="AdminTableActions">
      <Dropdown>
        <Dropdown.Toggle as={DropdownMenuToggle} bsPrefix="toggle">
          <SvgThreeDotsVertical />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {visibleActions?.map((action, index) => (
            <AdminTableAction item={item} action={action} key={index} />
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
