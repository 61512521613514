import { api } from '../../functions/api';
import { ApiPath } from '../../enums/ApiPath';

export function setNotificationsAsRead(unreadIds: number[]): Promise<void> {
  return api({
    url: ApiPath.NotificationsStatusRead,
    method: 'post',
    data: unreadIds,
  });
}
