import * as React from 'react';

function SvgSortAsc(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.724 11.943c.52.52 1.365.52 1.885 0L8 7.553l4.39 4.39a1.333 1.333 0 101.886-1.886L8.943 4.724a1.333 1.333 0 00-1.886 0l-5.333 5.333c-.52.52-.52 1.365 0 1.886z"
        fill="currentColor"
      />
      <path
        d="M8 27c.736 0 1.333-.597 1.333-1.333v-20a1.333 1.333 0 00-2.666 0v20C6.667 26.403 7.264 27 8 27z"
        fill="currentColor"
      />
      <rect width={14} height={2.67} rx={1.335} transform="matrix(1 0 0 -1 14 24)" fill="currentColor" />
      <rect width={12} height={2.67} rx={1.335} transform="matrix(1 0 0 -1 16 17.33)" fill="currentColor" />
      <rect width={10} height={2.67} rx={1.335} transform="matrix(1 0 0 -1 18 10.66)" fill="currentColor" />
    </svg>
  );
}

export default SvgSortAsc;
