import { useCallback } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

export function useDeleteItem<T>(strategy: (item: T) => Promise<any>) {
  const { t } = useTranslation();

  return useCallback((item: T) => {
    const id = toast.loading(t('Deleting'));

    return strategy(item)
      .then((response) => {
        toast.success(t('Deleted successfully'));
        return response;
      })
      .catch((reason) => {
        toast.error(t('Error while deleting'));
        return Promise.reject(reason);
      })
      .finally(() => toast.dismiss(id));
  }, [t, strategy]);
}
