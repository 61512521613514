import React, { ButtonHTMLAttributes, FC } from 'react';
import classNames from 'classnames';
import './NotificationsMenuItem.scss';
import { NotificationsMenuCounter } from './NotificationsMenuCounter';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  count?: number;
  active?: boolean;
}

export const NotificationsMenuItem: FC<Props> = ({ className, children, count, active = false, ...attrs }) => {
  return (
    <button
      className={classNames('NotificationsMenuItem', className, {
        '-active': active,
      })}
      {...attrs}
    >
      <span className="label">{children}</span>

      {typeof count === 'number' && count > 0 && <NotificationsMenuCounter count={count} active={active} />}
    </button>
  );
};
